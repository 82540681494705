import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Plus } from 'react-feather'
import { connect } from 'react-redux'
import { CDN_URL } from '../processENV'

export const ResultAssess = (props) => {

    const[image_bar,setImageBar]=useState()
    const[score_details,setScoreDetails]=useState()
    useEffect(()=>{

    },[])
  return (
    <div>

        <div className='col-md-12 mx-auto '>
             <p className='mt-5' style={{fontFamily:'Poppins-SemiBold', fontSize:16,width:'97%', textAlign:'center'}}>Here are your results</p>
          {score_details && score_details.length !==0 ?
          <>
           <p style={{fontFamily:'Poppins-SemiBold', fontSize:16,width:'97%', textAlign:'center',marginTop:20}}>
            Your Assesment score is {this.state.score_details.score} out of {this.state.score_details.total}
            </p>
          <div className='text-center'>
           {image_bar.length !==0 ? 
           <img 
                style={{width:350, height:200}}
               src={image_bar}
                resizeMode="contain"
           />
            :null} 
            <div style={{background:'#ddefe5',color:'#215352'}} className='col-md-5 mx-auto border shadow-sm mb-4' > 
            <h3 style={{textDecorationLine:'underline',color:'#215352'}} className='text-center pt-2'>About Phq-9</h3>
            <p className='text-left pt-2 pl-3'>Assessment Result is a log of marks/grades earned by the student for specific Assessment. An Assessment Result is created in the backend based on the marks entered in the Assessment Result Tool</p>
            </div>

            <div style={{background:'#fcefd2'}} className='col-md-4 mx-auto shadow-sm border-rounded m-1'>
              <h5  className='text-left pt-3 font-weight-bold'>what if the score is low?</h5>
                <div className='d-flex flex-column'>
                <span  className='text-left font-weight-bold p-1'><Plus size={18} />{' '}Point 1 </span>
                <span  className='text-left font-weight-bold p-1'><Plus size={18} />{' '}Point 2 </span>
                <span  className='text-left font-weight-bold p-1  pb-3'><Plus size={18} />{' '}Point 3 </span>
                </div>
            </div>
            <div style={{background:'#e8f9fa'}} className='col-md-4 mx-auto shadow-sm border-rounded m-1 mt-2'>
              <h5  className='text-left pt-3 font-weight-bold'>what if the score is Medium?</h5>
                <div className='d-flex flex-column'>
                <span  className='text-left font-weight-bold p-1'><Plus size={18}   />{' '}Point 1 </span>
                <span  className='text-left font-weight-bold p-1'><Plus size={18}  />{' '}Point 2 </span>
                <span  className='text-left font-weight-bold p-1  pb-3'><Plus size={18}  />{' '}Point 3 </span>

                </div>
            </div>
            <div style={{background:'#f8f2fa'}} className='col-md-4 mx-auto shadow-sm border-rounded m-1 mt-2'>
              <h5  className='text-left pt-3 font-weight-bold'>what if the score is High?</h5>
                <div className='d-flex flex-column'>
                <span  className='text-left font-weight-bold p-1'><Plus size={18}  />{' '}Point 1 </span>
                <span  className='text-left font-weight-bold p-1'><Plus size={18}  />{' '}Point 2 </span>
                <span  className='text-left font-weight-bold pb-3 p-1'><Plus size={18}  />{' '}Point 3 </span>

                </div>
            </div>
            <div className='col-md-6 mx-auto'>
              <h4 className='font-weight-bold mt-5 text-left pt-4'>Recommended courses</h4>
              </div>
            <div className='d-flex flex-row col-md-6 mx-auto mt-3'>
              <div className='border shadow-sm border-rounded m-2 pb-n1'>
              <img  style={{width:'100%'}}  src={`${CDN_URL}/Selfcare/breakup.png`} />
              <button style={{background:'#215352',color:'#fff'}} className='text-center border-top btn-block p-2 font-weight-bold'>Take Now</button>
              </div>
              <div className='border shadow-sm border-rounded m-2'>
              <img  style={{width:'100%'}}  src={`${CDN_URL}/Selfcare/improvingwork.png`} />
              <button style={{background:'#215352',color:'#fff'}} className='text-center border-top btn-block p-2 font-weight-bold'>Take Now</button>
              </div>
              <div className='border shadow-sm border-rounded m-2'>
              <img style={{width:'100%'}} src={`${CDN_URL}/Selfcare/copying.png`} />
              <button style={{background:'#215352',color:'#fff'}} className='text-center border-top btn-block p-2 font-weight-bold'>Take Now</button>
              </div>
            </div>
            
            <div className='col-md-6 mx-auto'>
              <h4 className='font-weight-bold mt-5 text-left pt-4'>Recommended session</h4>
              <div className='text-center mt-4'>
               <p>We recommnd you to take a session to make you better.</p> 
              </div>
              <div className='border shadow-sm border-rounded m-2 col-md-4 mx-auto p-0 mt-3 col-7'>
              <img style={{width:'100%'}} src={`${CDN_URL}/Websiteicons/Mobileicongray/Therapy.png`} />
              <button style={{background:'#215352',color:'#fff'}} className='text-center border-top btn-block p-2 font-weight-bold'>Book Now</button>
              </div>
              </div>
            <div className='mt-5'>
           <div style={{zIndex:999}} className='text-center mb-n4'>
           <img
            style={{width:'120px',height:'120px',zIndex:999}}
              src={`${CDN_URL}/newapp/rainbow.png`}
              />
           </div>
            <div  style={{zIndex:0}} className='col-md-4 mx-auto border shadow-sm rounded p-4 '>
              <p className='text-center' style={{fontFamily:'Poppins-Regular', fontSize:15,}}>
                {this.state.score_details.description}Assessment Result is a log of marks/grades earned by the student for specific Assessment. An Assessment Result is created in the backend based on the marks entered in the Assessment Result Tool.
              </p>
            </div>
            </div>
          </div></>:null}
          <div className='text-center mt-5 col-md-3 mx-auto'>
          <button 
          className='btn btn-block'
          style={{background:'#215352',color:'#fff',fontWeight:'bold',border:'none'}}
          onClick={()=>this.props.history.push('home')}>
           Go home
          </button>
          </div>
          </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ResultAssess);