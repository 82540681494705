import React from 'react';
import { CDN_URL } from '../../processENV';

const ShareContent = () => {
    const shareContent = () => {
        const message = encodeURIComponent(
          "Hey, check out this incredible mental health app I stumbled upon—it's been a game-changer for me! 💙 https://careme.health"
        );
      
        const whatsappUrl = `https://wa.me/?text=${message}`;
      
        window.open(whatsappUrl, '_blank');
      };
  return (
    <div className="col-md-6 mt-4 mx-auto d-flex justify-content-center">
      <div
        className="d-flex flex-row align-items-center flex-wrap p-3"
        style={{ width: '90%' }}
      >
        <img
          src={CDN_URL+"/APP/HomePage/shr1.png"}
          alt="Share Invite"
          style={{ height: 200, width: '40%', objectFit: 'contain' }}
        />
        <div className="ps-3" style={{ flex:1 }}>
          <p style={{ fontSize: '14px', color: '#000' }}>
            Good vibes are meant to be shared! Invite your friends to CareMe
            Health because everyone deserves support. 💙
          </p>
          <button
            className="btn btnColor borderRounded borderColor mt-2"
            style={{ borderRadius: 12 }}
            onClick={shareContent}
          >
            Share the Care
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareContent;
