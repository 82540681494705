import React from 'react';
import { CDN_URL } from '../../processENV';

const JoinCommunity = () => {

  return (
    <div className="col-md-6 mt-4 mx-auto d-flex justify-content-center">
      <div
        className="d-flex flex-row-reverse align-items-center flex-wrap mt-4"
        style={{ width: '86%' }}
      >
        <img
          src={CDN_URL+"/APP/HomePage/jcomm1.png"}
          alt="Join Community"
          className="img-fluid rounded"
          style={{ height: 180, flex: 0.8, objectFit: 'cover' }}
        />
        <div className="pe-3" style={{ flex: 0.8 }}>
          <h5 className="font-weight-bold" style={{ color: '#4a4a4a' }}>
            You’re Not Alone.
          </h5>
          <p style={{ fontSize: '14px', color: '#4a4a4a' }}>
            Real stories. Real support. Real people. Your safe space to talk,
            connect, and care. Click to become part of something meaningful.
          </p>
          <button
            className="btn btnColor borderRounded borderColor mt-2"
            style={{ borderRadius: 12 }}
            onClick={()=>window.location.open('https://careme.health/community')}
          >
            Join the Community
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
