import React, { useEffect, useState } from 'react';
import { Alert, Spinner, Button, Container, Row, Col, Card, Form } from 'react-bootstrap';
import TherapistProfile from './TherapistProfile';
import TherapistProfileSkeleton from './TherapistSkeleton';
import { postapi } from '../../Helpers/Api';
import FillingCircle from './LoadingAnimation';


const SelectTherapist = ({ productDetails, appointmentDetails, setAppointmentDetails }:any) => {
  const [therapistData, setTherapistData] = useState<any>({ loading: true, error: null, apidata: {} });
  const [newtherapistData, setNewTherapistData] = useState<any>({ loading: true, error: null, apidata: {} });
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchTherapist = async () => {
      const patient_n_key = localStorage?._patientKey;
      setTherapistData((prev:any) => ({ ...prev, loading: true, error: null }));
      const postData = JSON.stringify({
        app_prod_grp_key: productDetails?.app_prod_grp_key,
        therapy_type_id: appointmentDetails?.therapy_type_id,
        language: appointmentDetails?.language,
        gender: appointmentDetails?.gender,
        mode: appointmentDetails?.mode,
        patient_n_key
      });

      try {
        const resp = await postapi('/v1/match_therapist/', postData);
        if (resp.status === 'success') {
          const timeoutId = setTimeout(() => {
            setTherapistData({ apidata: resp.data, loading: false, error: null });
            setCount(resp?.employee_available_count);
            setAppointmentDetails({ ...appointmentDetails, employee_n_key: resp.data?.employee_n_key });
          }, 4500);
          return () => clearTimeout(timeoutId);
        } else {
          throw new Error('Failed to fetch therapist');
        }
      } catch (error:any) {
        setTherapistData((prev:any) => ({ ...prev, error: error?.message, loading: false }));
      }
    };

    fetchTherapist();
  }, []);

  useEffect(() => {
    const todayMatched = new Date().toISOString().split('T')[0] === therapistData?.apidata?.next_available_format;
    if (therapistData?.apidata?.next_available_format && !todayMatched) {
      handleSelected();
    }
  }, [therapistData?.apidata?.next_available_format]);

  const handleSelected = async () => {
    setNewTherapistData((prev:any) => ({ ...prev, loading: true, error: null }));
    const patient_n_key = localStorage?._patientKey;
    const postData = JSON.stringify({
      app_prod_grp_key: productDetails?.app_prod_grp_key,
      therapy_type_id: appointmentDetails?.therapy_type_id,
      language: appointmentDetails?.language,
      gender: appointmentDetails?.gender,
      mode: appointmentDetails?.mode,
      patient_n_key,
      selected_employee: therapistData?.apidata?.employee_n_key,
    });

    try {
      const resp = await postapi('/v1/match_therapist_today/', postData);
      if (resp.status === 'success') {
        setNewTherapistData({ apidata: resp.data, loading: false, error: null });
        setCount(resp?.employee_available_count);
        setAppointmentDetails({ ...appointmentDetails, employee_n_key: resp.data?.employee_n_key });
      }
    } catch (error:any) {
      setNewTherapistData((prev:any) => ({ ...prev, error: error.message, loading: false }));
    }
  };

  const handleSelecteds = () => {
    setAppointmentDetails({ ...appointmentDetails, employee_n_key: newtherapistData?.apidata?.employee_n_key });
    setTherapistData({...newtherapistData});
  };

  const { experience_value, speciality, first_name, last_name } = therapistData?.apidata || {};

  if (therapistData?.loading) return <FillingCircle />;
  if (therapistData?.error) {
    return (
      <Container className="text-center my-4">
        <p className="text-danger">{therapistData?.error}</p>
      </Container>
    );
  }

  return (
    <Container className="py-3 px-0 ">
      <Row className="mb-4">
        <Col>
          <h3 className="fontColor font-weight-bold text-left h4 mb-2">You’re One Step Closer to Feeling Better</h3>
          <p className="fontColor text-left h6 text-muted mb-0">
            Finding the right support matters. We've matched you with <strong>{first_name + ' ' + last_name}</strong>,
            a trusted expert committed to helping you heal, grow, and find clarity.
          </p>
        </Col>
      </Row>

      {newtherapistData?.apidata?.employee_n_key && (
        <Row  style={{
          flexDirection: "row", // Ensures text & button are in a row
          backgroundColor: "#FFE0B2",
          borderLeftWidth: 6,
          borderColor: "#d97706",
          borderRadius: 4,
          width: "100%",
          alignItems:'center'
        }} className="align-items-center flex-md-row flex-column bg-opacity-25 border-start border-5 border-warning rounded p-3 mb-3">
          <Col>
            <p className="mb-1 text-left">
              Your therapist's schedule is full today. Check out these available therapists who can support you right now!
            </p>
          </Col>
          <Col xs="auto">
            <Button
              variant="outline-dark btn-block btn bg-white px-3 py-2"
              size="sm"
              onClick={handleSelecteds}
              disabled={newtherapistData.loading}
            >
              {'Find Available Therapist'}
            </Button>
          </Col>
        </Row>
      )}

      {/* Therapist Profile */}
      <TherapistProfile {...therapistData?.apidata} />
    </Container>
  );
};

export default SelectTherapist;
