import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowMoreText from "react-show-more-text";
import { CDN_URL, REACT_APP_API_URL } from "../../processENV";
import { useEffect } from "react";
import { useState } from "react";
import { saveAs } from "file-saver";
import { ArrowRight } from "react-feather";
import dwnic from "../../../assets/images/dwnic.png";
import vwic from "../../../assets/images/vwic.png";
import JoinCommunity from "../Home/JoinCommunity";
import FirstSessionCallout from "../../NoAppointment";

const Exercise = (props) => {
  const [documentList, setDocumentList] = useState([]);
  const [url, setUrl] = useState([
    `${CDN_URL}/APP/untitled-1/1.png`,
   `${CDN_URL}/APP/untitled-1/3.png`,
    `${CDN_URL}/APP/untitled-1/4.png`,
    `${CDN_URL}/APP/untitled-1/5.png`,
  ]);
  const [urlss, setUrlss] = useState([
    `${CDN_URL}/APP/untitled-1/6.png`,
    `${CDN_URL}/APP/untitled-1/7.png`,
    `${CDN_URL}/APP/untitled-1/8.png`,
    `${CDN_URL}/APP/untitled-1/9.png`,
  ]);

  const downld = async (document_attachment, document_name) => {
    saveAs(document_attachment, document_name);
  };
  const getDocumentList = async () => {
    let data = { patient_n_key: localStorage.getItem("_patientKey") };
    fetch(`${REACT_APP_API_URL}/list_exerciseapp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("document list", res);
        setDocumentList(res.data);
      })
      .catch((err) => console.log("exercise list error", err));
  };
  useEffect(() => {
    getDocumentList();
  }, []);
  return (
    <div style={{height:'100vh'}} className="container">
      <div className="col-md-8 mx-auto mt-5  pt-5">
        <ToastContainer />
        <h3
          style={{ textDecorationLine: "underline" }}
          className="header_font_ _text_color text-center"
        >
          Therapy Exercise Planner
        </h3>
        {documentList.length !== 0 ? (
          <div className="mt-5">
            {documentList.map((i, indx) => {
              return (
                <div
                  style={{ border: "1px solid #215352" }}
                  className="row shadow-sm col-md-7  mt-3  d-flex mx-auto flex-row  position-relative  rounded"
                >
                  <div className=" p-2 row">
                    <img
                      src={
                        indx % 2 == 0
                          ? url[Math.floor(Math.random() * url.length)]
                          : urlss[Math.floor(Math.random() * urlss.length)]
                      }
                      style={{ width: "60px", height: "60px" }}
                    />
                    <div className="col p-2">
                      <p className="header_font_ _text_color"> {i.exe_name}</p>
                      <ShowMoreText
                        className="mt-n3 _text_color text_p_"
                        lines={2}
                        more="Show more"
                        less="Show less"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        <p className="mt-n3">{i.overview}</p>
                      </ShowMoreText>
                    </div>
                  </div>
                  <div
                    style={{ top: 20, right: 10 }}
                    className=" p-2  position-absolute"
                  >
                    {" "}
                    {i.attachment_type == "Link" ? (
                      <a href={i.url} target="_blank">
                        <img
                          src={vwic}
                          style={{ width: "20px", height: "20px" }}
                          className="dow_ic_exc"
                        />{" "}
                      </a>
                    ) : (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={dwnic}
                        onClick={() => downld(i.document, i.exe_name)}
                        className="dow_ic_exc"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
        <FirstSessionCallout />
        )}
      </div>
      <JoinCommunity />
    </div>
  );
};

export default Exercise;
