import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

const ProgressBar = ({ totalSteps, currentStep, height = 6, barColor = "#4CAF50" }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const clamped = Math.min(((currentStep || 0) / (totalSteps || 1)) * 100, 100);
    setProgress(clamped);
  }, [currentStep, totalSteps]);

  const springProps = useSpring({
    to: { width: `${progress}%` },
    config: { duration: 500 },
  });

  return (
    <div
      className="w-100 bg-light rounded"
      style={{ height: `${height}px`, overflow: "hidden" }}
    >
      <animated.div
        style={{
          ...springProps,
          height: "100%",
          backgroundColor: barColor,
          borderRadius: "3px",
        }}
      />
    </div>
  );
};

export default ProgressBar;
