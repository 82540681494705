import React from "react";

const Skeleton = () => {
  return (
    <div className="container my-4">
      <div className="d-flex flex-column">
        <div className="w-100 bg-light bg-opacity-25 rounded mb-3" style={{ height: '32px' }} />
        <div className="w-75 bg-light bg-opacity-25 rounded mb-4" style={{ height: '24px' }} />
      </div>

      {Array.from({ length: 5 }).map((_, indx) => (
        <div
          key={indx}
          className="bg-light bg-opacity-25 rounded mb-3"
          style={{ height: '40px' }}
        ></div>
      ))}
    </div>
  );
};

export default Skeleton;
