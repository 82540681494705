import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { animated, useSpring } from 'react-spring';
import './loadanim.css';

const messages = [
  "Finding the best match...",
  "Filtering therapists...",
  "Analyzing preferences...",
  "Matching criteria...",
  "Best therapist found!",
];

const FillingCircle = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [resetAnim, setResetAnim] = useState(false);

  const { height } = useSpring({
    from: { height: 150 },
    to: { height: 0 },
    config: { duration: 5000 },
    reset: resetAnim,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev < messages.length - 1 ? prev + 1 : prev));
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      setMessageIndex(0);
      setResetAnim(true);
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
      <div className="circle" style={{ width: '150px', height: '150px' }}>
        <div className="circle-fill" style={{ background: `linear-gradient(transparent ${100 - (height.animation?.to || 0)}%, #56D2A0 ${100 - (height.animation?.to || 0)}%)` }} />
        <div className="circle-overlay" />
        <div className="circle-fill-text">{messages[messageIndex]}</div>
      </div>
    </Container>
  );
};

export default FillingCircle;