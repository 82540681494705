
import React from "react";
import { CDN_URL } from "../processENV";

interface Question {
  headerQuestion: string;
  subHeader: string;
}

const TopQuestion: React.FC<Question> = ({ headerQuestion, subHeader }) => {
  return (
    <div className="d-flex align-items-start gap-3 py-4 px-md-3 px-0">
      {/* Image Section */}
      <div className="flex-shrink-0">
        <img
          width={70}
          height={70}
          alt="Meenakshi"
          className="rounded-circle border shadow-sm"
          src={`${CDN_URL}/aboutus/Meenakshi.png`}
        />
      </div>

      {/* Text Content */}
      <div className="d-flex flex-column text-start pl-3">
        <h3 className="fontColor font-weight-bold text-left h4 mb-2">{headerQuestion}</h3>
        <p className="fontColor text-left h6 text-muted mb-0">{subHeader}</p>
      </div>
    </div>
  );
};

export default TopQuestion;
