import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import axios from "axios";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import {
    AiFillEye,
    AiOutlineCloudDownload,
    AiOutlineDownload,
  } from "react-icons/ai";
  import {
    DownloadCloud,
    Eye,
    MinusCircle,
    Play,
    PlusCircle,
    Download,
    ArrowRight
  } from "react-feather";
import { REACT_APP_API_URL, getCurrencySymbol } from '../processENV';
import moment from 'moment';
import { BsDownload } from 'react-icons/bs';
import { postapi } from '../../Helpers/Api';
import FirstSessionCallout from '../NoAppointment';
class AppointmentDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            documentList: [], 
        };
    }

    componentDidMount() {
        this.getDocumentList();
      }
      getDocumentList = () => {
        let data = {
          patient_n_key: localStorage.getItem("_patientKey"),
        };
        console.log(data);
        fetch(`${REACT_APP_API_URL}/app_payment_history/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("document list", res);
            this.setState({ documentList: res.data });
          })
          .catch((err) => console.log("alcohol list error", err));
      };
      downloadInvoice=async(item)=>{
        let JsonData = JSON.stringify({
          "patient_n_key":localStorage._patientKey,
          "created_on": item.created_on,
          "discount": item.discount,
          "discount_amount":item.discount_amount,
          "paid_amount":item.amount,
          "payment_details": item.payment_details,
          "product": item.product,
          "per_session": item.split_session,
          "product_amount": item.product_amount,
          "total_session": item.total_session
        });
          const response = await postapi('/download_invoice/', JsonData);
          console.log(response)
          if(response){
            const blob = new Blob([response], { type: 'application/pdf' });
            saveAs(blob, `Invoice_${item.payment_details}.pdf`)
          }
      }
    render() {
        let { documentList } = this.state;
        return (
           
                  <div className="col-md-12 m-0 p-0 mt-5 pt-5" style={{height:'100vh'}}>
                    <h3 style={{textDecorationLine:'underline'}} className='header_font_ _text_color text-center'>Billing Statement History</h3>
                    <div className='col-md-6 mx-auto'>
                     {documentList.length !== 0 ? (
          <>
            {documentList.map((i, indx) => {
              return (
                <div  className=" col-md-7 border d-flex rounded shadow-sm mx-auto my-4 px-0 py-0">
                  <div className="p-2 d-flex justify-content-center secondColor align-self-center flex-column">
                    <p className="text-center pt-2 _text_color">Amount</p>
                    <p className="text-center pt-n3 _text_color">{getCurrencySymbol(i.currency)} {i.amount}</p>
                  </div>
                  <div className='d-flex flex-column ml-3 pt-3' >
                  <div className='d-flex flex-row _text_color mt-2'>
                    <p className=" ">Product:</p>
                    <p className='_text_color pl-2'>{i.product}</p>
                  </div>
                  <div className='d-flex flex-row mt-n2'>
                    <p className="_text_color">Paid on:</p>
                    <p className='_text_color pl-2'> {moment(i.created_on, "YYYY/MM/DD hh:mm:ss").format("DD-MM-YYYY hh:mm A")}</p>
                   </div>
                  </div>
                  <div className='align-self-center ml-auto mr-3'>
                    <BsDownload className='fontColor' onClick={()=>this.downloadInvoice(i)} size={20} />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <FirstSessionCallout />
        )}</div>
         </div>
              
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(AppointmentDetails);
