import React from 'react';
import { FaUser, FaUsers, FaStethoscope, FaWeixin } from 'react-icons/fa';

const services = [
  {
    id: '1',
    title: 'For Individual',
    description: '🌸 Feel calm, clear, and in control',
    icon: <FaUser />,
    color: '#F7F7FD',
  },
  {
    id: '2',
    title: 'For Couples',
    description: '🌷🌷 Grow love, trust, and connection',
    icon: <FaUsers />,
    color: '#E9F5EC',
  },
  {
    id: '3',
    title: 'Psychiatric',
    description: '🌼 Think clear, heal deep, feel steady',
    icon: <FaStethoscope />,
    color: '#FDF6EC',
  },
  {
    id: '4',
    title: 'Coach Support',
    description: '🌻 Shine bright, trust more, rise strong',
    icon: <FaWeixin />,
    color: '#F4F2F8',
  },
];

const adjustColor = (color) => {
  const colorMap = {
    '#F7F7FD': '#E0E0F0',
    '#E9F5EC': '#CBE8D6',
    '#FDF6EC': '#F0E2D0',
    '#F4F2F8': '#DBD6E6',
  };
  return colorMap[color] || '#ddd';
};

const QuickAccess = ({ goItem }) => {
  return (
    <div className="col-md-5  mx-auto my-5">
      <div className="row">
        {services.map((item,index) => (
          <div className="col-md-6 mb-4" key={item.id}>
            <div
              className="p-4 roundedBorder borderColor secondColor h-100"
              style={{ borderColor: '#675bab', cursor: 'pointer' }}
              onClick={() => goItem(index)}
            >
              <div
                className="d-flex align-items-center  justify-content-center mb-3"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                }}
              >
                <div style={{ fontSize: 35, color: '#675bab' }}>{item.icon}</div>
              </div>
              <h5 style={{ fontWeight: 600, color: '#333' }}>{item.title}</h5>
              <p style={{ fontSize: 13, color: '#4a4a4a' }}>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickAccess;
