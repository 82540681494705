import React from 'react';
import { FaUser, FaUsers, FaStethoscope } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

const services = [
  {
    id: '1',
    title: 'For Individuals',
    description: '🌿 Personal therapy to help you heal, find clarity, and regain control.',
    icon: <FaUser />,
    color: '#F7F7FD',
  },
  {
    id: '2',
    title: 'For Couples',
    description: '💬 Strengthen your bond, rebuild trust, and improve communication.',
    icon: <FaUsers />,
    color: '#E9F5EC',
  },
  {
    id: '3',
    title: 'Psychiatric',
    description: '🧠 Expert care for mental health concerns and medication guidance.',
    icon: <FaStethoscope />,
    color: '#FDF6EC',
  },
  {
    id: '4',
    title: 'Coach Support',
    description: '🔥 Break through self-doubt, build confidence, and reach your goals.',
    icon: <FiMessageCircle />,
    color: '#F4F2F8',
  },
];

const adjustColor = (color) => {
  const colorMap = {
    '#F7F7FD': '#E0E0F0',
    '#E9F5EC': '#CBE8D6',
    '#FDF6EC': '#F0E2D0',
    '#F4F2F8': '#DBD6E6',
  };
  return colorMap[color] || '#ddd';
};

const ServicesGrid = ({ onPressProd }) => {
  return (
    <div className="col-md-8 mx-auto my-5">
      <div className="row">
        {services.map((item, index) => (
          <div className="col-md-6 mb-4 " key={item.id}>
            <div
              className="p-4 rounded shadow-sm h-100 "
              style={{ backgroundColor: item.color, cursor: 'pointer' }}
              onClick={() => onPressProd(index)}
            >
              <div
                className="d-flex align-items-center justify-content-center rounded-circle mb-3"
                style={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: adjustColor(item.color),
                }}
              >
                <div style={{ fontSize: '26px', color: '#222' }}>{item.icon}</div>
              </div>
              <h5 className="fw-bold" style={{ color: '#333' }}>{item.title}</h5>
              <p className="text-muted" style={{ fontSize: '14px' }}>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesGrid;
