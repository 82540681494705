import React from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Store from "../../../Store";
import { ScheduleAction } from "../../../Actions/ScheduleAction";

const TherapistLeft = (props) => {
  const dataDetails = props?.location?.state?.empDetails[0];
  const therapistName = dataDetails?.first_name + " " + dataDetails?.last_name;
  const item = props?.location?.state?.data;
  item.switch = true;

  const getStep = (title) => {
    switch (title) {
      case "Couple Therapy":
        return 1;
      case "Psychiatric Consultation":
        return 0;
      default:
        return 1;
    }
  };
  const getApprodKey = (title) => {
    switch (title) {
      case "Couple Therapy":
        return { app_prod_n_key: "APP_PROD-5" };
      case "Psychiatric Consultation":
        return { app_prod_n_key: "APP_PROD-3" };
      default:
        return { app_prod_n_key: item.app_prod_n_key };
    }
  };
  return (
    <Container>
      <div className="col-md-8 mx-auto pt-md-5 mt-md-5 pt-3">
        <p
          className="header_font_ fontColor text-center"
          style={{
            fontSize: 30,
            fontWeight: "600",
            textDecorationLine: "underline",
          }}
        >
          We're Here for You — Every Step of the Way
        </p>
        <p
          className="text_p_ fontColor"
          style={{ fontSize: 20, paddingVertical: 18 }}
        >
          We want to let you know that your current therapist,{" "}
          {therapistName || ""} is no longer with CareMe Health. We understand
          that building trust with your therapist is deeply personal, and
          changes like this can feel unsettling.
        </p>
        <p>
          Please know that your care and well-being remain our highest priority.
          At CareMe Health, we’re more than just a service — we’re a dedicated
          partner in your mental health journey. Our team is here to support you
          with understanding, empathy, and consistency through this transition
          and beyond.
        </p>
        <p
          className="text_p_ fontColor"
          style={{ fontSize: 20, color: "#615e5e", paddingVertical: 18 }}
        >
          If you're ready, we’ll help match you with a new care partner who
          truly understands where you've been — and where you want to go. You
          deserve to feel supported, heard, and cared for, always.
        </p>
        <p className="text_p_ fontColor">Let’s take this next step together.</p>
        <div className="mx-auto text-center mt-5">
          <button
            className="btn rounded  btn newbtn header_font_ px-5"
            onClick={() => {
              Store.dispatch(
                ScheduleAction({
                  scheduleStep: getStep(item?.title),
                  scheduleCurrentStep: getStep(item?.title),
                  problem: getApprodKey(item?.title),
                  mode: "Video",
                  data1: item,
                })
              );
              props?.history?.push("/schedule/appointment", { data1: item });
            }}
          >
            Switch Therapist
          </button>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(TherapistLeft);
