import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  FiArrowLeft,
  FiArrowRight,
  FiList,
  FiGlobe,
  FiUser,
  FiUsers,
  FiVideo
} from "react-icons/fi";
import TherapyComponent from "./TherapyComponent";
import SelectTherapist from "./SelectTherapist";
import AppointmentConfirmation from "./AppointmentConfirmation";
import {
  MdList,
  MdLanguage,
  MdGroup,
  MdVideoCall,
  MdPerson,
  MdEvent,
  MdSchedule,
  MdCheck,
} from "react-icons/md";

const steps = [
  {
    id: 1,
    endPoint: '/v1/list_therapy_types/',
    dataKey: 'therapy_type_id',
    accessKey: 'therapy_type_id',
    headerQuestion: 'We’re Here for You ✨ ',
    subHeader: `Whatever you're going through, you don’t have to face it alone. Let’s find the right support to help you feel lighter, stronger, and more in control.`,
    labelKey: 'therapy',
    icon: <MdList size={20} />,
    label: 'Therapy Type',
  },
  {
    id: 2,
    endPoint: '/v1/therapy_language/',
    dataKey: 'language',
    accessKey: '',
    headerQuestion: 'Speak Your Heart, in Your Language 💬',
    subHeader: `We want you to feel truly heard and understood. Choose the language you're most comfortable with, and we’ll connect you with a professional who speaks it too.`,
    labelKey: '',
    icon: <MdLanguage size={20} />,
    label: 'Language',
  },
  {
    id: 3,
    endPoint: '/v1/therapy_gender/',
    dataKey: 'gender',
    accessKey: '',
    headerQuestion: 'Who Makes You Feel Most Comfortable? 🌿',
    subHeader: 'We want you to feel safe and at ease. Let us know if you have a preference, and we’ll match you with the right therapist for you.',
    labelKey: '',
    icon: <MdGroup size={20} />,
    label: 'Gender',
  },
  {
    id: 4,
    endPoint: '/v1/therapy_mode/',
    dataKey: 'mode',
    accessKey: '',
    headerQuestion: 'How Would You Like to Connect? 💬',
    subHeader: `Choose the way that feels most comfortable for you—your space, your pace, your choice.`,
    labelKey: '',
    icon: <MdVideoCall size={20} />,
    label: 'Appointment Mode',
  },
  {
    id: 5,
    content: 'This is step 5 content.',
    icon: <MdPerson size={20} />,
    label: 'Select Therapist',
  },
  {
    id: 6,
    endPoint: '/app_rescheduled_date/',
    dataKey: 'date',
    accessKey: '',
    headerQuestion: 'Pick a Date That Works for You 📅',
    subHeader: 'Your time, your pace. Choose a date that fits best, and let’s get you the support you deserve.',
    labelKey: '',
    icon: <MdEvent size={20} />,
    label: 'Appointment Date',
  },
  {
    id: 7,
    endPoint: '/app_rescheduled_time/',
    dataKey: 'appoint_time',
    accessKey: '',
    headerQuestion: 'Pick a Time That Works Best for You ⏳',
    subHeader: `Your well-being is a priority. Select a time that fits your schedule and let's get started on your journey to healing.`,
    labelKey: '',
    icon: <MdSchedule size={20} />,
    label: 'Appointment Time',
  },
  {
    id: 8,
    content: 'This is step 5 content.',
    icon: <MdCheck size={20} />,
    label: 'Confirm',
  },
];

export default function AnimatedModalStepper({item}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [appointmentDetails, setAppointmentDetails] = useState({
    therapy_type_id: '',
    language: '',
    mode: '',
    date: '',
    appoint_time: '',
    gender: '',
    employee_n_key: '',
    app_payment_n_key: '',
    pat2_key: '',
  });
  const [productDetails, setProductDetails] = useState({});
  const requiredFields = [
    'therapy_type_id',
    'language',
    'gender',
    'mode',
    'employee_n_key',
    'date',
    'appoint_time',
  ];

  const [disabled, setDisabled] = useState(false);
  const childApiRef = useRef(null);

  useEffect(() => {
    setDisabled(!appointmentDetails[requiredFields[currentStep]]);
  }, [ item, currentStep, ...requiredFields]);

  useEffect(() => {
    console.log(item);
    setProductDetails({
      ...productDetails,
      ...item,
    });
    setAppointmentDetails({
      ...appointmentDetails,
      ...item,
    });
  }, [item]);
  const slideIn = useSpring({
    from: { opacity: 0, transform: "translateX(100px)" },
    to: { opacity: 1, transform: "translateX(0px)" },
    config: { duration: 300 },
    reset: true,
  });

  const handleBack = async () => {
    window.location.href="/home"
  };
  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      childApiRef?.current?.appointmentConfirm();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  return (
    <div style={{height:'100vh'}} className="d-flex align-items-center px-0 col-sm-12 col-md-8 mx-auto justify-content-center statusColor">
      <animated.div
        className="col-md-8 col-sm-12 p-1 rounded p-md-3 d-flex flex-column justify-content-between h-100 pt-5 shadow-none"
        style={slideIn}
      >
        {/* Stepper Header */}
        <div className="d-none d-md-flex w-100 align-items-center justify-content-center pb-4 border-bottom">
          {steps.map((step, index) => (
            <React.Fragment key={step.id}>
              {/* Step Icon Circle */}
              <div className="d-flex flex-column align-items-center text-center">
                <div
                  className={`rounded-circle d-flex align-items-center justify-content-center border border-2 fw-semibold ${
                    index === currentStep
                      ? "border-primary text-primary"
                      : index < currentStep
                      ? "btn newbtn"
                      : "border-secondary text-muted"
                  }`}
                  style={{ width: "40px", height: "40px" }}
                >
                  {step.icon}
                </div>
              </div>

              {/* Connector Line */}
              {index < steps.length - 1 && (
                <div
                  className="flex-grow-1 mx-2"
                  style={{
                    height: "3px",
                    backgroundColor: index < currentStep ? "#beb2f9" : "#dee2e6",
                    minWidth: "14px",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>


        {/* Middle Component */}
        <animated.div
          key={currentStep}
          className="mt-4 text-center w-100 overflow-auto"
          style={{ ...slideIn, maxHeight: "450px" }}
        >
         {currentStep === 4 ? (
            <SelectTherapist
              productDetails={productDetails}
              appointmentDetails={appointmentDetails}
              setAppointmentDetails={setAppointmentDetails}
            />
          ) : currentStep === 7 ? (
            <AppointmentConfirmation
              productDetails={productDetails}
              appointmentDetails={appointmentDetails}
              ref={childApiRef}
            />
          ) : (
            productDetails?.app_prod_grp_key && (
              <TherapyComponent
                endPoint={steps[currentStep]?.endPoint ?? ''}
                dataKey={steps[currentStep]?.dataKey ?? ''}
                labelKey={steps[currentStep]?.labelKey ?? ''}
                accessKey={steps[currentStep]?.accessKey ?? ''}
                headerQuestion={steps[currentStep]?.headerQuestion ?? ''}
                subHeader={steps[currentStep]?.subHeader ?? ''}
                moveToNext={handleNext}
                appointmentDetails={appointmentDetails}
                productDetails={productDetails}
                setAppointmentDetails={setAppointmentDetails}
                currentStep={currentStep}
                patient_n_key={localStorage?._patientKey}
              />
            )
          )}
        </animated.div>

        {/* Footer Buttons */}
        <div className="mt-4 d-flex mt-auto justify-content-between">
          <button
            onClick={handlePrevious}
            disabled={currentStep === 0}
            className="btn newbtn px-4 roundedBorder py-2 d-flex align-items-center"
          >
            <FiArrowLeft className="mr-2" /> Previous
          </button>
          <button
            onClick={handleNext}
            disabled={currentStep !== 4 && currentStep !== 7 && disabled}
            className="btn newbtn px-4 roundedBorder py-2  d-flex align-items-center"
          >
             {currentStep === 4
              ? 'Schedule Session'
              : currentStep === 7
                ? 'Confirm Appointment'
                : 'Next'} <FiArrowRight className="ml-2" />
          </button>
        </div>
      </animated.div>
    </div>
  );
}