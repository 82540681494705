// pages/AssessmentPage.jsx
import { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';

import { ArrowLeft, ArrowRight } from 'react-feather';
import { postapi } from '../../Helpers/Api';
import WellnessIntro from './AssessmentIntro';
import AssessmentStep from './AssessmentSetup';
import ClosingScreen from './ClosingScreen';
import ProgressBar from './ProgressBar';
import { emotionalQuestions, financialQuestions, getWellnessCategory, physcialQuestions, professionalQuestions, sleepQuestions, socialQuestions, sumScores } from './Questions';
import WelcomeScreen from './WelcomeScreen';

const AssessmentPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentQstnIndex, setCurrentQstnIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [assessments, setAssessments] = useState([
    {},
    {},
    {title: 'Emotional', assessmentSet: emotionalQuestions},
    {},
    {title: 'Financial', assessmentSet: financialQuestions},
    {},
    {title: 'Sleep', assessmentSet: sleepQuestions},
    {},
    {title: 'Professional', assessmentSet: professionalQuestions},
    {},
    {title: 'Physical', assessmentSet: physcialQuestions},
    {},
    {title: 'Social', assessmentSet: socialQuestions},
  ]);
  const currentAssessment = assessments[currentStep];
  const TIME_PER_QUESTION = 30; // 30 seconds per question
  const wellnessSteps = {
    1: 'Emotional',
    3: 'Financial',
    5: 'Sleep',
    7: 'Professional',
    9: 'Physical',
    11: 'Social',
  };
  const btnTitles = {
    0: 'Start Your Assessment',
    1: 'Let’s Check In',
    3: 'Let’s Dive In',
    5: 'Check Your Sleep Health',
    7: 'Let’s Assess Your Work-Life Balance',
    9: 'Let’s Check Your Physical Health',
    11: 'Let’s Strengthen Your Social Circle',
  };
  const isSpecialStep = btnTitles.hasOwnProperty(currentStep);
  const isLastStep =
    currentStep === 12 &&
    currentAssessment?.assessmentSet?.length &&
    currentAssessment.assessmentSet.length - 1 === currentQstnIndex;

  const isLongTitle = isSpecialStep && btnTitles[currentStep]?.length > 20;

  const handleNext = () => {
    if (isLastStep) {
     console.log('last');
    } else if (currentAssessment?.assessmentSet) {
      if (currentQstnIndex < currentAssessment.assessmentSet.length - 1) {
        setCurrentQstnIndex(prev => prev + 1);
      } else {
        setCurrentQstnIndex(0);
        setCurrentStep(prev => prev + 1);
      }
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    if(currentStep===0){
    console.log('back')
    }
    else if(currentAssessment?.assessmentSet && currentQstnIndex > 0) {
      setCurrentQstnIndex(prev => prev - 1);
    } else {
      setCurrentStep(prev => {
        const prevStep = prev - 1;
        const prevAssessment = assessments[prevStep]; // Get previous assessment
        const lastQuestionIndex = prevAssessment?.assessmentSet?.length
          ? prevAssessment.assessmentSet.length - 1
          : 0;
  
        setCurrentQstnIndex(lastQuestionIndex); 
        return prevStep;
      });
    }
  };

  const handleChange = val => {
    setAssessments(prevAssessments => {
      const updatedAssessments = [...prevAssessments];
      updatedAssessments[currentStep].assessmentSet[currentQstnIndex].answer =
        val;
      return updatedAssessments;
    });
    const timeoutId = setTimeout(() => {
      handleNext();
    }, 0);
    return () => clearTimeout(timeoutId);
  };

  const getNextBtn = () => {
    if (isLastStep) {
      return 'Submit';
    } else if (isSpecialStep && btnTitles[currentStep]) {
      return btnTitles[currentStep];
    } else {
      return 'Next';
    }
  };

  const handleSubmitResults = async () => {
    const patient_n_key = localStorage._patientKey;

    const formattedAssessments = assessments.reduce((acc, item) => {
      if (item.title && item.assessmentSet) {
        const score = sumScores(item.assessmentSet);
        const key = item.title.toLowerCase();
        const result = getWellnessCategory(score, `${key}WellnessScoring`);

        acc[key] = {
          focus_areas: [],
          assessment_score: score,
          assessment: item.assessmentSet,
          total_score: 25,
          progress_status: result?.scoreLabel,
        };
      }
      return acc;
    }, {});

    const focus_areas = [];
    const positive_areas = [];

    Object.entries(formattedAssessments).forEach(([key, value]) => {
      const label = value.progress_status;
      if (label === 'excellent') positive_areas.push(key);
      else focus_areas.push(key);
    });

    const payload = JSON.stringify({
      ...formattedAssessments,
      focus_areas,
      positive_areas,
      patient_n_key,
      aspiration: {},
      academic: {},
      personal: {},
    });

    try {
      setLoading(true);
      const res = await postapi('/welbeing_post/', payload);
      if (res.status === 'success') {
        setCurrentStep(prev => prev + 1);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const disabled = !(isSpecialStep || currentAssessment?.assessmentSet[currentQstnIndex]?.answer);

  return (
    <Container className="col-md-12 pt-md-4 mx-auto">
      {loading && (
        <div className="text-center my-5">
          <Spinner animation="border" />
        </div>
      )}

      {/* Progress bar */}
      {!isSpecialStep&&currentStep!==13? <ProgressBar totalSteps={12} currentStep={currentStep} height={10}  barColor={'#278c2c'} />:null}


      {currentStep === 0 && <WelcomeScreen onPressNext={() => setCurrentStep(1)} />}
      {currentStep === 13 && <ClosingScreen />}
      {wellnessSteps[currentStep] && (
          <WellnessIntro step={wellnessSteps[currentStep]} />
        )}
      {currentAssessment?.assessmentSet && (
        <AssessmentStep
          assessmentCategory={assessments[currentStep]}
          currentIndex={currentQstnIndex}
          onSelectAnswer={val => handleChange(val)}
          />
      )}

     {currentStep>0 && currentStep !==13 && <Row className="mt-5 justify-content-between">
        <Col xs="auto">
          <Button
            variant="outline-secondary"
            className='statusColor borderColor fontColor'
            onClick={handlePrevious}
            disabled={false}
          >
            <ArrowLeft className='mr-2' />
            Previous
          </Button>
        </Col>
        {currentAssessment?.assessmentSet?
      <p className=''>{currentQstnIndex+1 +' / '+ currentAssessment?.assessmentSet.length}</p>
    :null}
        <Col xs="auto">
          <Button 
            className='statusColor borderColor fontColor'
           onClick={isLastStep?handleSubmitResults:handleNext}
          disabled={disabled}>
            {getNextBtn()}
            <ArrowRight className='ml-2' />
          </Button>
        </Col>
      </Row>}
    </Container>
  );
};

export default AssessmentPage;
 