import React from 'react';
import { Container, Card, Image, Alert } from 'react-bootstrap';
import { CDN_URL } from '../processENV';

const wellnessData = {
  Emotional: {
    title: '🌿 Emotional Wellness',
    description:
      'Your mind is your power. 🧠💙 How well do you handle stress, emotions, and daily challenges? This section will help you see how emotionally balanced you are and where you can improve.',
    fact: '✨ Fun fact: Emotional resilience can boost happiness and reduce stress by 30%!',
    imageUrl: `${CDN_URL}/APP/Wellbeing/2a.png`,
  },
  Financial: {
    title: '💰 Financial Wellness',
    description:
      'Money can’t buy happiness, but financial stability sure helps. 💸✨ This section looks at how well you manage financial stress, savings, and overall money confidence.',
    fact: '📊 Did you know? 72% of people say finances are their biggest stressor—you’re not alone!',
    imageUrl: `${CDN_URL}/APP/Wellbeing/1.png`,
  },
  Sleep: {
    title: '💤 Sleep Wellness',
    description:
      "Sleep is your brain’s best friend. 😴💤 Are you getting enough rest, or do late-night scrolls keep you up? Sleep impacts your mood, focus, and energy—this section helps you find out if you're getting the quality rest you need.",
    fact: '🛏 Poor sleep can increase stress by 60%—let’s fix that!',
    imageUrl: `${CDN_URL}/APP/Wellbeing/3.png`,
  },
  Professional: {
    title: '👔 Professional Wellness',
    description:
      'Work hard, but don’t burn out. 🚀💼 Do you feel fulfilled, or are you just counting down to the weekend? This section helps assess job satisfaction, work-life balance, and career stress levels.',
    fact: "💡 77% of employees experience burnout at some point—let’s make sure you're not one of them.",
    imageUrl: `${CDN_URL}/APP/Wellbeing/4.png`,
  },
  Physical: {
    title: '🏃‍♂️ Physical Wellness',
    description:
      'Your body, your energy, your strength. 💪⚡ Are you fueling yourself with the right habits—exercise, nutrition, and self-care? This section evaluates your physical well-being and lifestyle choices.',
    fact: '🔥 Pro tip: Just 30 minutes of movement daily can reduce stress and boost happiness!',
    imageUrl: `${CDN_URL}/APP/Wellbeing/5.png`,
  },
  Social: {
    title: '🤝 Social Wellness',
    description:
      'You’re not meant to do life alone. 🌍💛 Strong social connections improve mental well-being and even help you live longer! Let’s assess your friendships, support system, and sense of belonging.',
    fact: '👀 Fact: Loneliness can increase the risk of premature death by 26%—but meaningful relationships can change that.',
    imageUrl: `${CDN_URL}/APP/Wellbeing/7.png`,
  },
};

const WellnessIntro = ({ step }) => {
  const data = wellnessData[step];

  if (!data) return null;

  return (
    <Container className="mt-2">
      <Card
        className="shadow-none border-0 pt-2 rounded"
      >
        <Card.Body>
          <h3 className="font-weight-bold fontColor mb-3">
            {data.title}
          </h3>

          <p
            className="mb-4 fs-5  fontColor text-secondary"
          >
            {data.description}
          </p>

          <div className="text-center mb-4">
            <Image
              src={data.imageUrl}
              alt={data.title}
              style={{ maxWidth: 300, height: 300 }}
            />
          </div>

          <Alert
            variant=""
            className="px-3 py-2 mt-3"
            style={{
              backgroundColor: '#dde5e2',
              borderRadius: 4,
              fontStyle: 'italic',
              fontSize: 15,
              color: '#323232',
              fontFamily: 'Poppins-Regular',
            }}
          >
            {data.fact}
          </Alert>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default WellnessIntro;
