import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { postapi } from "../../../Helpers/Api";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const CreditPage = (props) => {
  const [chatItem, setChatItem] = useState();
  useEffect(() => {
    getChat();
  }, []);

  const getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    const res = await postapi("/app_chathome/", data);
    if (res.status === "success") {
      setChatItem(res);
    } else {
      console.log("meeting id", res.data);
    }
  };

  const joinFreeChat = async () => {
    const pt_key = localStorage._patientKey;
    const JsonData = { patient_n_key: localStorage._patientKey };
    const res = await postapi("/counselling_room/", JsonData);
    if (res.status === "success") {
      let JsonData1 = {
        channelName: res.channelid,
        uid: pt_key,
      };
      const resp = await postapi("/agora_rtctoken/", JsonData1);
      if (resp.status == "success") {
        localStorage.uid = pt_key;
        localStorage.channelName = res.channelid;
        props.history.push("/chatscreen", {
          channelId: res.channelid,
          token: resp.rtm_data,
          uid: pt_key,
          typeofchat: "CareRoom",
          freeChat: res,
          firstChat: res.new_user,
          defkey:'left',
          doctor_photo:
            chatItem &&
            chatItem?.support_chat &&
            chatItem?.support_chat.length !== 0
              ? chatItem?.support_chat[0]?.doctor_photo
              : null,
          doctor_name:
            chatItem &&
            chatItem?.support_chat &&
            chatItem?.support_chat.length !== 0
              ? chatItem.support_chat[0]?.doctor_name
              : null,
          disable:
            chatItem &&
            chatItem?.support_chat &&
            chatItem?.support_chat.length !== 0
              ? chatItem.support_chat[0]?.expired
              : false,
        });
      }
    }
  };
  return (
    <Container>
      <div className="col-md-8 mx-auto pt-md-5 mt-md-5 pt-3">
        <p
          className="header_font_  fontColor text-center"
          style={{
            fontSize: 30,
            fontWeight: "600",
            textDecorationLine: "underline",
          }}
        >
          {" "}
          Session Booking Update
        </p>
        <p
          className="text_p_"
          style={{ fontSize: 20, color: "#615e5e", paddingVertical: 18 }}
        >
          It's important for us to let you know that your chosen therapist has
          recently moved on from CareMe Health. However, your credits with us
          remain valid and secure. 
        </p>
        <p
          className="text_p_"
          style={{ fontSize: 20, color: "#615e5e", paddingVertical: 18 }}
        >
          To ensure you continue your care seamlessly, we invite you to connect
          with a coach who can guide you in selecting a new therapist that
          aligns with your needs. Your journey and care are our utmost priority.
        </p>
        <div className="mx-auto text-center mt-5">
          <button
            onClick={()=>joinFreeChat()}
            className="btn newbtn rounded header_font_ px-5"
          >
            Speak to a Coach
          </button>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(CreditPage);
