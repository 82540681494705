import { Badge, Card, Col, Container, Image, Row } from "react-bootstrap";
import { FaBriefcase, FaCalendarAlt, FaCreditCard, FaVideo } from "react-icons/fa";
import ReadMore from "../../Helpers/Readmore";
import { getCurrencySymbol } from "../processENV";
import moment from "moment";
import { BsShieldFillCheck } from "react-icons/bs";


const TherapistProfile = ({
  role,
  followup_price_max,
  languages_known,
  profile_picture,
  currency,
  mode_of_telehealth,
  next_available,
  followup_price,
  session_minutes,
  no_of_session_taken,
  first_name,
  last_name,
  experience_value,speciality,
  description,
  next_available_format,
}) => {
  const therapistName =
  (first_name + last_name).length > 16
    ? first_name
    : first_name + " " + last_name;

const mapData = [
  {
    title: "Followup Session Fees",
    icon: <FaCreditCard className="mr-2" />,
    data: `${getCurrencySymbol(currency)} ${followup_price} - ${getCurrencySymbol(
      currency
    )} ${followup_price_max} / session`,
  },
  {
    title: "Professional Experience",
    icon: <FaBriefcase className="mr-2" />,
    data: `${experience_value}+ years`,
  },
  {
    title: "Telehealth Options",
    icon: <FaVideo className="mr-2" />,
    data: `Virtual`,
  },
];


  return (
    <Container className="my-4 p-0">
    <Card className="p-1 shadow-none statusColor border-0">
    <Row className="p-0 mx-0">
      <Col md={5} xs={6} className="text-center">
        <Badge bg="warning" className="borderColor fontColor px-3 py-2 secondColor roundedBorder w-100 mb-2">
          <BsShieldFillCheck size={20} /> Your Best Match
        </Badge>
        <Image
          src={profile_picture}
          rounded
          style={{ objectFit: "cover", maxHeight: '240px', borderRadius: "10px" }}
          className="border rounded border-white border-3"
        />
        <div className="mt-2 d-flex align-items-center justify-content-center small px-3 py-1 borderColor fontColor secondColor roundedBorder">
          <FaCalendarAlt size={20} className="me-2" />
          Next Available: {moment(next_available_format, "YYYY/MM/DD").format("MMM D")}
        </div>
      </Col>

      <Col className="p-0" md={6} xs={5}>
        <Card style={{ minHeight: '230px', backgroundColor: '#E0DCF0' }} className="p-3 shadow-none borderColor roundedBorder">
          <h5 className="font-weight-bold text-left mb-2">{therapistName}</h5>
          <p className="text-left medium text_p_ font-weight-light">
            <ReadMore text={description} maxWords={420} />
          </p>
        </Card>

        <div className="d-md-flex d-none flex-wrap gap-2 mt-3">
          {mapData.map((item, idx) => (
            <div
              key={idx}
              className="roundedBorder secondColor fontColor px-3 py-2 m-1 small d-flex align-items-center borderColor"
            >
              {item.icon}
              {item.data}
            </div>
          ))}
        </div>

        {speciality?.length > 0 && (
          <div className="d-md-flex d-none flex-wrap gap-2 mt-3">
            {speciality.slice(0, 6).map((item, idx) => (
              <div
                key={idx}
                className="roundedBorder secondColor fontColor px-3 py-2 m-1 small d-flex align-items-center borderColor"
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </Col>
      <div className="d-flex d-md-none flex-wrap gap-2 mt-3">
          {mapData.map((item, idx) => (
            <div
              key={idx}
              className="roundedBorder secondColor fontColor px-3 py-2 m-1 small d-flex align-items-center borderColor"
            >
              {item.icon}
              {item.data}
            </div>
          ))}
        </div>

        {speciality?.length > 0 && (
          <div className="d-flex d-md-none flex-wrap gap-2 mt-3">
            {speciality.slice(0, 6).map((item, idx) => (
              <div
                key={idx}
                className="roundedBorder secondColor fontColor px-3 py-2 m-1 small d-flex align-items-center borderColor"
              >
                {item}
              </div>
            ))}
          </div>
        )}
    </Row>
    </Card>
  </Container>
  );
};

export default TherapistProfile;
