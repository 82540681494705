import moment from "moment";
import React from "react";
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "react-bootstrap";
import { ChevronRight } from "react-feather";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { withRouter } from "react-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { RescheduleAction } from "../../../Actions/RescheduleAction";
import { ScheduleAction } from "../../../Actions/ScheduleAction";
import { ScheduleApp } from "../../../Actions/ScheduleApp";
import { TherapyJourneyAction } from "../../../Actions/TherapyJourneyAction";
import { postapi } from "../../../Helpers/Api";
import Store from "../../../Store";
import {
  CDN_URL,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_BU_API_URL,
  SITE_URL,
} from "../../processENV";
import JoinCommunity from "./JoinCommunity";
import QuickAccess from "./Services";
import ShareContent from "./ShareContent";
const publicIp = require("react-public-ip");

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class SelectedService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      meetingId: "",
      profiledocname: "",
      serverTime: "",
      cancel: "",
      audioLock: false,
      confirmAppointment: [],
      pending: [],
      followUp: [],
      products: [],
      successfulPayment: [],
      therapyForms: [],
      pat_name: "",
      chat_item: [],
      selectP: "",
      old: false,
      new: false,
      empDetails: "",
      events_: [],
      eventsprev_: [],
      _wellbeing: false,
      _fol_therapist: false,
      imageUrl:'',
    };
  }

  componentDidMount() {
    let k = localStorage._patientKey;
    console.log(k.split("-")[2]);
    this.getSelectedAppointment();
    this.getNewProd();
    this.getFollowUp();
    this.getPending();
    this.getPatname();
    this.getPaid();
    this.getChat();
    this.getEvent();
    this.getTherapyAsses();
    this.interval = setInterval(() => this.getOrgTime(), 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getEvent = async () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_BU_API_URL}/carme_events/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          console.log("events_", response);
          this.setState({ events_: response.alldata });
        }
      });
    fetch(`${REACT_APP_API_URL}/list_webinar/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          console.log("eventsprev_", response);
          this.setState({ eventsprev_: response.results });
        }
      });
  };

  getPatname = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ pat_name: res.data.first_name });
      });
  };
  getTherapyAsses = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/home_therapyforms/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          console.log(response);
          this.setState({
            therapyForms: response.data,
            _wellbeing: response.welbeing,
          });
        }
      });
  };

  getOrgTime = async () => {
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          this.setState({ serverTime: response.data });
        }
      });
  };
  formattedDate = (date) => {
    if (!date) return "Invalid Date"; // Handle null/undefined
    return moment(date).format("ddd, MMM DD, YYYY");
  };
  dateConversion = (rdate) => {
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      "," +
      date.getFullYear() +
      " at " +
      date.getHours() +
      ":" +
      (date.getMinutes() == "0" ? date.getMinutes() + "0" : date.getMinutes()) +
      " " +
      (parseInt(date.getHours()) < 12 ? "AM" : " PM")
    );
  };

  dateConversionTherapy = (rdate) => {
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      "," +
      date.getFullYear()
    );
  };

  cancelAppointment = (aKey, reason) => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      appointment_n_key: aKey,
      status: "Cancelled",
      cancelled_reason: reason,
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/app_cancel_appoint/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("cancel appointment", res.data);
        if (res.status === "success") {
          this.getSelectedAppointment();
        } else {
          console.log("cancel appointment", res.data);
        }
      });
  };
  scheduleApp = async (aKey, id, key) => {
    let item = key;
    console.log(aKey, id, key);
    if (item.app_prod_grp_key) {
      let data = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
      };
      const res = await postapi("/previous_check/", data);
      if (res.status == "success") {
        if (res.data[0].is_active == 1) {
          this.setState({ _fol_therapist: false });
          let appointmentDetail = {
            doc_app_id: res.data[0].employee_n_key,
            app_prod_grp_key: item.app_prod_grp_key,
            app_payment_n_key: item.app_payment_n_key,
            addon: item.addon || false,
          };
          Store.dispatch(
            ScheduleApp({
              data1: appointmentDetail,
            })
          );
          this.props.history.push(`/scheduleapp/followup/id`, {
            data1: appointmentDetail,
          });
        } else {
          this.props.history.push("/creditsleft", {
            selectP: item,
            creditModal: true,
            empDetails: res.data,
            _fol_therapist: true,
          });
        }
      } else {
        Store.dispatch(
          ScheduleApp({
            data1: item,
          })
        );
        this.props.history.push(`/scheduleapp/followup/${id}`, {
          data1: item,
        });
      }
    }
  };
  rescheduleAppointment = (item) => {
    console.log(item);
    if (item.overall_status === "Rescheduled") {
      Swal.fire({
        title: 'Reschedule Limit Reached',
        html: `
          <div style="text-align: left; font-size: 14px; line-height: 1.6; padding-top:20px">
            <p>We understand that things don’t always go as planned, and we truly value your time.</p>
      
            <p>However, appointments can only be rescheduled once to ensure fairness and to respect the time of our mental health professionals.</p>
      
            <p>That said, we’re here to support you. If you're experiencing a genuine need to reschedule again, we encourage you to 
              <strong>connect with your coach directly</strong>. They’ll do their best to explore any available options with you.
            </p>
      
            <p style="margin-top: 15px;"><strong>Need help? Our care team is here for you:</strong></p>
            <p>📧 Email: <a href="mailto:care@careme.health">care@careme.health</a></p>
            <p>📞 Call: <a href="tel:+917829002002">+91 7829-002-002</a></p>
            <p>💬 WhatsApp: <a href="https://wa.me/917829002002" target="_blank">Message us</a></p>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        width: 600,
      });
      
    } else {
      Store.dispatch(
        RescheduleAction({
          prodId: item,
        })
      );
      this.props.history.push(`/reschedule/appointment/date`, { data: item });
    }
  };
  getStep = (title) => {
    return 0;
  };
  getApprodKey = (title) => {
    switch (title) {
      case "Couple Therapy":
        return { app_prod_n_key: "APP_PROD-5" };
      case "Psychiatric Consultation":
        return { app_prod_n_key: "APP_PROD-3" };
      default:
        return null;
    }
  };
  scheduleAppointment = async (item) => {
    console.log(item);
    if (item.app_prod_grp_key) {
      let data = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
      };
      const res = await postapi("/previous_check/", data);
      if (res.status == "success") {
        if (res?.data[0]?.is_active !== 1) {
          let appointmentDetail = {
            doc_app_id: res.data[0].employee_n_key,
            app_prod_grp_key: item.app_prod_grp_key,
            app_payment_n_key: item.app_payment_n_key,
            previous_book: "Yes",
            addon: item.addon || false,
          };
          Store.dispatch(
            ScheduleApp({
              data1: appointmentDetail,
            })
          );
          this.props.history.push(`/scheduleapp/followup/id`, {
            data1: appointmentDetail,
          });
        } else {
          this.props.history.push("/creditsleft", {
            selectP: item,
            creditModal: true,
            empDetails: res.data,
          });
        }
      } else {
        Store.dispatch(
          ScheduleAction({
            scheduleStep: this.getStep(item.title),
            scheduleCurrentStep: this.getStep(item.title),
            problem: this.getApprodKey(item.title),
            mode: "Video",
            data1: item,
          })
        );
        this.props.history.push("/schedule/appointment", { data1: item });
      }
    }
  };
  sessionPay = async (item, index) => {
    console.log(item);
    let data = {
      patient_n_key: localStorage._patientKey,
      app_prod_grp_key: item.app_prod_grp_key,
    };
    const res = await postapi("/previous_check/", data);
    if (res.status == "success") {
      if (res.data[0].is_active == 1) {
        Store.dispatch(
          TherapyJourneyAction({
            indx: index,
          })
        );
        localStorage.setItem("_i", index);
        this.props.history.push(`/therapyjourney/selectSession/`);
      } else {
        this.props?.history?.push("/therapistleft", {
          data: item,
          empDetails: res.data,
        });
      }
    }
  };
  getNewProd = async () => {
    let data = {
      ip: (await publicIp.v4()) || "",
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("new prod", res.data);
        if (res.status === "success") {
          this.setState({ products: res.data });
        } else {
          console.log("new prod", res.data);
        }
      });
  };
  getFollowUp = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("followup service", res.data);
        if (res.status === "success") {
          this.setState({ followUp: res.data });
        } else {
          console.log("followup service", res.data);
        }
      });
  };
  getPaid = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_payment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("paid service", res.data);
        if (res.status === "success") {
          this.setState({ successfulPayment: res.data });
        } else {
          console.log("pending service", res.data);
        }
      });
  };
  getPending = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_followups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("pending service", res.data);
        if (res.status === "success") {
          this.setState({ pending: res.data });
          this.getImageUrl(res?.data[0]?.doc_app_id);
        } else {
          console.log("pending service", res.data);
        }
      });
  };
  getImageUrl=async(docId)=>{
        if(!docId) return;
        const param = JSON.stringify({employee_n_key: docId});
        const resp = await fetch(`${REACT_APP_API_URL}/get_therapist_profile/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: param,
        });
        const res = await resp.json();
        console.log(res);
        if (res) {
          this.setState({imageUrl:res[0]?.emp_photo});
        }
  }
  getSelectedAppointment = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_appoints/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("selected service", res.data);
        if (res.status === "success") {
          this.setState({ confirmAppointment: res.data });
        } else {
          console.log("selected service", res.data);
        }
      });
  };
  changeAudio() {
    const timer = setTimeout(() => {
      this.setState({ audioLock: false });
    }, 240000);
    return () => clearTimeout(timer);
  }

  getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/app_chathome/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          this.setState({ chat_item: res });
        } else {
          console.log("meeting id", res.data);
        }
      });
  };

  async joinFreeChat() {
    const pt_key = localStorage._patientKey;
    const JsonData = {
      patient_n_key: localStorage._patientKey,
    };
    fetch(REACT_APP_API_URL + "/counselling_room/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === "success") {
          let JsonData1 = {
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(JsonData1),
          })
            .then((response) => response.json())
            .then(async (resp) => {
              if (resp.status == "success") {
                localStorage.uid = pt_key;
                localStorage.channelName = res.channelid;
                this.props.history.push("/chatscreen", {
                  channelId: res.channelid,
                  token: resp.rtm_data,
                  uid: pt_key,
                  typeofchat: "CounsellingRoom",
                  freeChat: res,
                  firstChat: res.new_user,
                  doctor_photo:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item?.support_chat[0]?.doctor_photo
                      : null,
                  doctor_name:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item.support_chat[0]?.doctor_name
                      : null,
                  disable:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item.support_chat[0]?.expired
                      : false,
                });
              }
            });
        }
      });
  }

  getMeetingid = (item, id) => {
    if (item.mode_of_telehealth === "Video") {
      let data = {
        patient_n_key: localStorage.getItem("_patientKey"),
        appointment_n_key: id,
      };
      fetch(`${REACT_APP_API_URL}/join_now_app/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("meeting id", res.data);
          if (res.status === "success") {
            this.setState({ meetingId: res.data });
            let id = res.data.split("/")[4];
            console.log("meeting Id", id);
            window.location.replace(`${REACT_APP_BASE_URL}/patient/` + id);
          } else {
            console.log("meeting id", res.data);
          }
        });
    }
    if (item.mode_of_telehealth === "Audio") {
      this.setState({ audioLock: true }, () => {
        this.changeAudio();
      });
      var now_time = "";
      fetch(`${REACT_APP_API_URL}/org_current_time/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            now_time = response.data;
            var meetingTime = "";
            meetingTime = item.appointment_date;
            const currentTime = moment(response.data).toDate();
            console.log("This_is_current_time", currentTime);
            console.log(moment(this.state.serverTime).toDate());
            var meetingDate = moment(meetingTime).toDate();
            let differenceTIme =
              (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
            var differenceSeconds = parseInt(differenceTIme) / 60;
            if (differenceSeconds <= 10) {
              let data = {
                org_n_key: "ORGID-1",
                appointment_n_key: id,
              };
              fetch(`https://getehr.curegrid.co/api/connect_audio/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(data),
              })
                .then((response) => response.json())
                .then(async (res) => {
                  if (res.status === "success") {
                    console.log("audio id", res);
                  } else {
                    console.log("meeting id", res.data);
                  }
                });
            } else {
              alert(
                `Your Appointmet will beign at ${moment(
                  item.appointment_date
                ).format("DD-MM-YYYY")} at ${item.appointment_time}`
              );
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching current time:", error);
        });
    }
    if (item.mode_of_telehealth === "Chat") {
      var now_time = "";
      fetch(`${REACT_APP_API_URL}/org_current_time/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            now_time = response.data;
            var meetingTime = "";
            meetingTime = item.appointment_date;
            const currentTime = moment(response.data).toDate();
            console.log("This_is_current_time", currentTime);
            console.log(moment(this.state.serverTime).toDate());
            var meetingDate = moment(meetingTime).toDate();
            let differenceTIme =
              (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
            var differenceSeconds = parseInt(differenceTIme) / 60;

            if (differenceSeconds <= 10) {
              let JsonData1 = {
                channelName: item.appointment_n_key,
                uid: localStorage._patientKey,
              };
              fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(JsonData1),
              })
                .then((response) => response.json())
                .then(async (resp) => {
                  if (resp.status == "success") {
                    localStorage.uid = localStorage._patientKey;
                    localStorage.channelName = item.appointment_n_key;
                    this.props.history.push("/chatscreen", {
                      channelId: item.appointment_n_key,
                      empKey: item?.doc_app_id,
                      token: resp.rtm_data,
                      uid: item.patient_n_key,
                      mode: item.mode_of_telehealth,
                      typeofchat: "TeleHealth",
                      planitem: item,
                      doctor_photo: item.doctor_profile,
                      doctor_name: item.doctor_name,
                    });
                  }
                });
            } else {
              this.props.history.push("/waitingscreen", {
                item: item,
                typeofchat: "TeleHealth",
              });
            }
          }
        });
    }
  };
  cancelReason = async (item) => {
    // Step 1: Show cancellation explanation and policy
    const policyHtml = `
      <div style="text-align: left; font-size: 14px;">
        <p ><strong style="font-size: 18px;">Are you sure you want to cancel your appointment?</strong></p>
        <p>We understand that plans can change. Your well-being is important to us, and we're here whenever you're ready.</p>
        <hr />
        <h6><strong>Cancellation & Refund Policy:</strong></h6>
        <ul style="padding-left: 20px;">
          <li style="padding-top:6px"><strong>Full Refund</strong> – Cancel <strong>more than 24 hours</strong> before your appointment.</li>
          <li style="padding-top:6px"><strong>50% Refund</strong> – Cancel <strong>between 24 and 12 hours</strong> before the scheduled time.</li>
          <li style="padding-top:6px"><strong>No Refund</strong> – Cancel <strong>less than 12 hours</strong> before the session.</li>
          <li style="padding-top:6px"><strong>Same-Day Bookings:</strong>
            <ul style="margin-top: 5px;">
              <li style="padding-top:6px"><strong>50% fee</strong> if more than 12 hours remain.</li>
              <li style="padding-top:6px"><strong>100% fee</strong> if less than 12 hours remain.</li>
            </ul>
          </li>
        </ul>
        <p style="margin-top:10px;text-align:center">
          <a href="https://careme.health/terms" target="_blank" style="text-decoration: underline;">View Full Cancellation Policy</a>
        </p>
      </div>
    `;
  
    const confirm = await Swal.fire({
      title: '',
      html: policyHtml,
      showCancelButton: true,
      confirmButtonText: 'Yes, Continue',
      cancelButtonText: 'Cancel',
      width: 600,
    });
  
    if (confirm.isConfirmed) {
      // Step 2: Ask for reason
      const { value: reasonText } = await Swal.fire({
        input: "textarea",
        inputLabel: "Could you tell us the reason for cancellation?",
        inputPlaceholder: "Type your message here...",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        customClass: {
          validationMessage: "my-validation-message",
        },
        preConfirm: (value) => {
          if (!value || value.trim().length <= 15) {
            Swal.showValidationMessage("Reason should be at least 15 characters!");
            return false;
          } else {
            this.cancelAppointment(item?.appointment_n_key, value);
          }
        },
      });
    }
  };
  
  goToPayment = async (i) => {
    const { confirmAppointment, successfulPayment, followUp, pending } =
      this.state;
    let k_ = localStorage.getItem("_patientKey");
    const ptkey = k_.split("-")[2];
    const initial = "therapy";
    const title = i.app_prod_grp_key;
    console.log(i, title);
    if (title == "APP-PROD-CAT-4") {
      this.joinFreeChat();
    }
    if (title == "APP-PROD-CAT-1") {
      if (
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-1")))
      ) {
        console.log("yes");
      } else {
        // window.location.assign(`http://careme.health/${ptkey}/individual-therapy/${initial}`)
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/individual-therapy/${initial}`
        );
      }
    }
    if (title == "APP-PROD-CAT-2") {
      if (
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-2")))
      ) {
        console.log("yes");
      } else {
        // window.location.assign(`http://careme.health/${ptkey}/couple-therapy/${initial}`)
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/couple-therapy/${initial}`
        );
      }
    }
    if (title == "APP-PROD-CAT-3") {
      if (
        confirmAppointment &&
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment &&
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp &&
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending &&
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-3")))
      ) {
        console.log("yes");
      } else {
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/psychiatric-consultation/${initial}`
        );
      }
    }
  };
  showbutton = (item) => {
    let nowDate = moment(this.state.serverTime).toDate();
    let appDate = moment(item.appointment_date).toDate();
    var diff = (appDate.getTime() - nowDate.getTime()) / 1000;
    var differenceSeconds = parseInt(diff) / 60;
    if (differenceSeconds >= 60) {
      return true;
    } else return false;
  };
  showAppointment = (item) => {
    let nowDate = moment(this.state.serverTime).toDate();
    let appDate = moment(item.appointment_date).toDate();
    var diff = (appDate.getTime() - nowDate.getTime()) / 1000;
    var differenceSeconds = parseInt(diff) / 60;
    if (differenceSeconds >= -180) {
      return true;
    } else return false;
  };
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  formatTime = (input) => moment(input, "HH:mm:ss").format("HH:mm");
  getTelehealthIcon = (mode, keyId) => {
    const keyName = keyId === "APP-PRO-CAT-3" ? "Psychiatrist" : "Therapist";
    switch (mode) {
      case "Video":
        return `Video Call with ${keyName}`;
      case "Audio":
        return `Audio Call with ${keyName}`;
      case "Chat":
        return `Text Chat with ${keyName}`;
      default:
        return "";
    }
  };
  getOrdinalSuffix(num) {
    const rem10 = num % 10;
    const rem100 = num % 100;

    if (rem100 >= 11 && rem100 <= 13) {
      return "th";
    }

    switch (rem10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  render() {
    if (
      this.state.doctor_profile === "" ||
      this.state.doctor_profile === null
    ) {
      var docname = this.state.doctor_name;
      var res = docname.charAt(0);
      this.setState({
        profiledocname: res,
      });
    }
    let {
      confirmAppointment,
      successfulPayment,
      creditModal,
      products,
      followUp,
      pending,
      therapyForms,
      _wellbeing,
      _welluser,
      events_,
      eventsprev_,
    } = this.state;
    const ptkey = localStorage.getItem("_patientKey");

    return (
      <div
        className="container m-0 p-md-0 px-4 position-relative"
        style={{ overflowY: "scroll", marginTop: 20 }}
      >
        <div className="col-md-12 mx-auto">
          {this.state.pat_name && (
            <div className="mt-md-5 mt-5 pl-md-5 pl-1 pt-md-2 pt-5">
              <h2
                style={{ fontFamily: "Source Serif Pro" }}
                className="font-weight-bold ml-md-5 ml-1 pl-md-5 pl-1"
              >
                Hi, {this.capitalize(this.state.pat_name)} !
              </h2>
            </div>
          )}
        </div>
        {therapyForms?.length !== 0 || !_wellbeing ? (
          <div className="col-md-5 mx-auto mt-md-5 mt-3 ">
            <h3
              style={{ marginLeft: "-10px" }}
              className="mb-4 text-left fontColor font-weight-bold text_p_"
            >
              To-do's
            </h3>
          </div>
        ) : null}
        {therapyForms && therapyForms.length !== 0 ? (
          <div className="col-md-5 d-flex flex-row mx-auto secondColor borderColor justify-content-between shadow-sm mt-md-3 mt-3 p-4 roundedBorder ">
            <h5 className="text-left text_p_ pt-3">
              Your Clinician has assigned you an assessment -{" "}
              {therapyForms[0]?.form_name}, click here to complete.
            </h5>
            <button
              className="btn font-weight-bold ml-auto"
              onClick={() =>
                this.props.history.push("therapyAsses", {
                  order_form_id: therapyForms[0].order_form_id,
                })
              }
            >
              <ChevronRight className="fontColor" size={28} />
            </button>
          </div>
        ) : null}
        {therapyForms && therapyForms.length === 0 && !_wellbeing ? (
          <>
            <div className="col-md-5 d-flex flex-row mx-auto secondColor borderColor justify-content-between shadow-sm mt-md-3 mt-3 p-4 roundedBorder ">
              <h5 className="text_p_ text-left pr-3">
                Check in with yourself! Start your wellbeing Assessment now.
              </h5>
              <button
                className="btn font-weight-bold ml-auto"
                onClick={() => {
                  window.location.href = "/wellbeing-profile";
                }}
              >
                <ChevronRight className="fontColor" size={28} />
              </button>
            </div>
          </>
        ) : null}

        <Modal centered lg show={creditModal}>
          {/* <ModalHeader style={{textAlign:'center'}} >Choose an option to continue with your appointment</ModalHeader> */}
          <ModalBody style={{ textAlign: "center" }}>
            {this.state.empDetails[0] &&
            this.state.empDetails[0].is_active == 0 ? (
              <div className="prec_check_ text_p_ font-weight-bold pt-5">
                Hey, we recently had to let our therapist{" "}
                {`${
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].first_name) +
                  " " +
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].last_name)
                }`}{" "}
                go. If you would like to continue with her/him, send an email{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].email_id}
                </span>{" "}
                or call{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].phone_number}{" "}
                </span>
                <br />
                <div>
                  <br />
                  <p className="text_p_">
                    You're not alone and we're here for you. We have therapists
                    who can help you rebuild your life, learn and grow from the
                    experience, and get back to where you were before this
                    happened. Please reach out if you are ready to begin.
                  </p>
                </div>
                <br />
                <span style={{ fontSize: 12 }}>
                  Our support number: +91 7829-002-002
                </span>
                <div
                  style={{ width: "70%" }}
                  onClick={() => this.setState({ old: false, new: true })}
                  className="prec_check mt-4 border rounded text-center mx-auto py-3"
                >
                  {this.state.new ? (
                    <BsCheck2Circle
                      size={25}
                      style={{ marginRight: "4px" }}
                      className="icn_span"
                    />
                  ) : (
                    <BsCircle
                      style={{ marginRight: "4px" }}
                      size={15}
                      className="icn_span"
                    />
                  )}{" "}
                  I would like to switch therapist
                </div>
              </div>
            ) : (
              <div
                onClick={() => this.setState({ old: true, new: false })}
                className="prec_check mt-4 border rounded"
              >
                {this.state.old ? (
                  <BsCheck2Circle size={30} className="icn_span" />
                ) : (
                  <BsCircle size={15} className="icn_span" />
                )}{" "}
                I would like to continue with my previous therapist
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.setState({ creditModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={this.state.old || this.state.new ? false : true}
              onClick={() => {
                this.setState({ creditModal: false });
                if (this.state.old) {
                  console.log("d");
                }
                if (this.state.new) {
                  if (this.state._fol_therapist) {
                    Store.dispatch(
                      ScheduleAction({
                        data1: this.state.selectP,
                      })
                    );
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                      _fol_therapist: true,
                    });
                  } else {
                    if (
                      this.state.selectP.title === "Psychiatric Consultation"
                    ) {
                      let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
                      Store.dispatch(
                        ScheduleAction({
                          scheduleStep: 1,
                          scheduleCurrentStep: 1,
                          problem: selectedProblem,
                          mode: "Video",
                          data1: this.state.selectP,
                        })
                      );
                    } else {
                      Store.dispatch(
                        ScheduleAction({
                          data1: this.state.selectP,
                        })
                      );
                    }
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                    });
                  }
                }
              }}
            >
              Continue
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {confirmAppointment.length !== 0 ||
        successfulPayment.length !== 0 ||
        pending.length !== 0 ||
        followUp.length !== 0 ? (
          <div className="col-md-5 mx-auto mt-md-5 mt-3 ">
            <h3 className="mb-4 mt-md-4 text-left fontColor font-weight-bold text_p_">
              Your Session
            </h3>
          </div>
        ) : null}
        {/* {confirmAppointment.length !==0 &&   <h2 className='upcome_appoint'>Upcoming Appointments</h2>} */}
        {confirmAppointment.length !== 0 &&
          confirmAppointment.map((item, index) => {
            return (
              <>
                {this.showAppointment(item) && (
                  <div className="shadow-sm mt-5 col-md-5 d-flex flex-column mx-auto align-items-center justify-content-center secondColor  borderColor roundedBorder p-3">
                    <div className="d-flex justify-content-center align-items-start">
                      <Image
                        src={
                          item?.doctor_profile ||
                          "https://cdn.careme.health/CaremeLogo/final%20logo%20with%20slogan%20copy.png"
                        }
                        roundedCircle
                        style={{
                          width: 80,
                          marginTop: "-50px",
                          height: 80,
                          objectFit: "cover",
                          border: "1px solid #333",
                        }}
                      />
                    </div>
                    <div>
                      <div className="d-flex justify-content-center pt-2 align-items-center mb-1">
                        <h5 className="mb-0 text-center fontColor">
                          {item?.doctor_name}
                        </h5>
                        <a
                          href={`https://careme.health/provider/${item.doc_app_id}/`}
                          target="_blank"
                          rel="noreferrer"
                          className="ms-2 text-decoration-none"
                        >
                          <FaInfoCircle
                            className="ml-2"
                            size={18}
                            color="#333"
                          />
                        </a>
                      </div>
                      <p className="mb-1 fw-semibold fontColor">
                        Appointment Confirmed
                      </p>
                      <p className="mb-1 text-center fw-bold">
                        {this.formattedDate(item?.appointment_date)}
                      </p>
                      <p className="mb-1  text-center fw-bold">
                        {this.formatTime(item?.appointment_time)} -{" "}
                        {this.formatTime(item?.end_time_format)} (IST)
                      </p>
                      <p className="text-muted text-sm-center mb-1">
                        {this.getTelehealthIcon(
                          item?.mode_of_telehealth,
                          item?.app_prod_grp_key
                        )}
                      </p>
                      {item.mode_of_telehealth === "Audio" && (
                        <p
                          className="text-muted text-center"
                          style={{ fontSize: 12 }}
                        >
                          Our therapist will reach out to you shortly from this
                          number (+91 8037367667).
                        </p>
                      )}
                      <div className="text-center mt-3">
                        <button
                          onClick={() => {
                            this.getMeetingid(
                              item,
                              item && item.appointment_n_key
                            );
                          }}
                          className="btn newbtn roundedBorder px-4"
                        >
                          {item.mode_of_telehealth === "Video"
                            ? "Join Call"
                            : item.mode_of_telehealth === "Chat"
                            ? "Join Chat"
                            : "Call Now"}
                        </button>
                      </div>

                      {this.showbutton(item) && (
                        <div className="d-flex justify-content-center gap-4 mt-2">
                          <Button
                            variant="link"
                            className="fontColor px-2"
                            onClick={() => this.cancelReason(item)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="link"
                            className="fontColor px-2"
                            onClick={() => this.rescheduleAppointment(item)}
                          >
                            Reschedule
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            );
          })}

        {/* { confirmAppointment.length==0 && successfulPayment.length==0 && pending.length !== 0 &&  <h2 style={{fontFamily:'Source Serif Pro'}}  className='text-center mt-5 pt-5 mb-3'> Book your Follow-up Session</h2>} */}
        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length !== 0 &&
          pending.map((item, index) => {
            return (
              <div className="col-md-6 mx-auto  d-flex justify-content-center mt-5 mb-4">
                <div
                  className="position-relative d-flex align-items-center borderColor roundedBorder secondColor rounded-4 shadow-sm px-4 py-4"
                  style={{ width: "90%", maxWidth: "960px" }}
                >
                  {/* Profile Image (circular, offset left) */}
                  <div
                    className="position-absolute top-50 translate-middle-y"
                    style={{ left: "-35px" }}
                  >
                    <img
                      src={this.state.imageUrl}
                      alt="Doctor"
                      className="borderColor"
                      style={{
                        height: "70px",
                        width: "70px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  {/* Text + Button */}
                  <div className="ml-4 pl-3 w-100">
                    <h5 className="text_p_">
                      You’ve already done meaningful work in our first{" "}
                      <span className="font-weight-bolder">
                        {parseInt(item?.total_session) -
                          parseInt(item?.remaining_session) -
                          1 <=
                        0
                          ? 1
                          : parseInt(item?.total_session) -
                            parseInt(item?.remaining_session) -
                            1}
                      </span>{" "}
                      session. </h5>
                      <h5>If you're ready, your{" "}
                      <span className="font-weight-bolder">
                        {parseInt(item?.total_session) -
                          parseInt(item?.remaining_session)}
                      </span>{" "}
                      <span className="font-weight-bolder">
                        {this.getOrdinalSuffix(
                          parseInt(item?.total_session) -
                            parseInt(item?.remaining_session)
                        )}
                      </span>{" "}
                      of{" "}
                      <span className="font-weight-bolder">
                        {item?.total_session}{" "}
                      </span>{" "}
                      is waiting — we’ll move at your pace, as always.
                    </h5>
                    <button
                      className="btn newbtn roundedBorder btn-block py-2 display-2 px-4 mt-2"
                      onClick={() =>
                        this.scheduleApp(
                          item && item.appointment_n_key,
                          item.doc_app_id,
                          item
                        )
                      }
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        {/* {confirmAppointment.length==0 && successfulPayment.length==0 && pending.length==0&& followUp.length !== 0 &&  <h2  className='upcome_appoint'>Book Your Follow-up Appointments</h2>} */}
        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length == 0 &&
          followUp.length !== 0 &&
          followUp.map((item, index) => {
            return (
              <div className="col-md-6 mx-auto  d-flex justify-content-center mt-5 mb-4">
                <div
                  className="position-relative d-flex align-items-center borderColor roundedBorder secondColor rounded-4 shadow-sm px-4 py-4"
                  style={{ width: "90%", maxWidth: "960px" }}
                >
                  {/* Profile Image (circular, offset left) */}
                  <div
                    className="position-absolute top-50 translate-middle-y"
                    style={{ left: "-45px" }}
                  >
                    <img
                      src={item.doctor_photo}
                      alt="Doctor"
                      className="borderColor"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  {/* Text + Button */}
                  <div className="ml-5 pl-3 w-100">
                    <h5 className="text_p_">
                      Your journey matters, and your courage is already shining
                      through. This care plan was crafted to honor your unique
                      story and support your healing. At your pace, we’ll walk
                      together — celebrating your strength and the possibilities
                      ahead. You are seen, supported, and never alone.
                    </h5>
                    <button
                      className="btn newbtn roundedBorder btn-block py-2 display-2 px-4 mt-2"
                      onClick={() => this.sessionPay(item, index)}
                    >
                      View my care plan
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        {/* {confirmAppointment.length ==0 && successfulPayment.length !== 0 && successfulPayment.length !==0 &&  <h2 className='upcome_appoint'>Schedule Your Appointments</h2>} */}
        {confirmAppointment.length == 0 &&
          successfulPayment.length !== 0 &&
          successfulPayment.map((item, index) => {
            return (
              <div className="col-md-5 mx-auto draw p-0">
                <Card className="shadow-none  p-0 secondColor borderColor roundedBorder">
                  <Row noGutters className="align-items-center p-0">
                    <Col md={4} className="d-none d-lg-block">
                      <img
                        src={`${CDN_URL}/APP/HomePage/bkg.png`}
                        alt="Appointment"
                        style={{ maxHeight:'150px',width:'150px', objectFit: 'cover',borderTopLeftRadius:'16px',borderBottomLeftRadius:'16px' }}
                      />
                    </Col>
                    <Col md={8} className="px-3">
                      <h5 className="mb-2 fontColor pt-2 h5 font-weight-bold">Book your session</h5>
                      <p className="text-muted" style={{ fontSize: '0.9rem' }}>
                        You’ve made the commitment. Now it’s time to take your space.
                        Your therapist is here, holding that space for you.
                      </p>
                      <Button
                        variant="outline-primary"
                        onClick={() => this.scheduleAppointment(item)}
                        className="my-2 btn newbtn btn-block"
                        style={{ borderRadius: '12px', fontWeight: 600 }}
                      >
                        Book Now
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </div>
            );
          })}
        <div className="col-md-5 mx-auto mt-md-5 mt-3">
          <h3 className="mb-4 text-left fontColor font-weight-bold text_p_">
            How we can support you?
          </h3>
        </div>
        {products && (
          <QuickAccess goItem={(index) => this.goToPayment(products[index])} />
        )}
        <JoinCommunity />
        <ShareContent />
      </div>
    );
  }
}

export default withRouter(SelectedService);
