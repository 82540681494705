const scoreValues = [
  {label: 'Never', value: 1},
  {label: 'Rarely', value: 2},
  {label: 'Sometimes', value: 3},
  {label: 'Often', value: 4},
  {label: 'Always', value: 5},
];

const reverseScores = scoreValues.map((item, index, arr) => ({
  label: item.label,
  value: arr[arr.length - 1 - index].value,
}));

export const emotionalQuestions = [
  `I feel capable of handling life’s challenges.`,
  `I often feel overwhelmed or anxious.`,
  `I experience joy and satisfaction in daily life.`,
  `I can effectively manage stress.`,
  `I feel emotionally supported by people around me.`,
].map((question, index) => ({
  question,
  answerOption: index === 1 ? reverseScores : scoreValues,
  answer: '',
}));

export const financialQuestions = [
  'I feel confident about my financial future.',
  'I have an emergency fund that can cover 3+ months of expenses.',
  'I worry about my financial situation affecting my well-being.', // Reverse scored
  'I can pay my bills and essential expenses without stress.',
  'My financial situation allows me to do the things I enjoy.',
].map((question, index) => ({
  question,
  answerOption: index === 2 ? reverseScores : scoreValues,
  answer: '',
}));

export const sleepQuestions = [
  `I get at least 7 hours of sleep on most nights.`,
  `I wake up feeling well-rested and refreshed. `,
  `I have trouble falling or staying asleep.`,
  `My sleep is not disrupted by stress or anxiety.`,
  `My sleep schedule is consistent on most days.`,
].map((question, index) => ({
  question,
  answerOption: index === 2 ? reverseScores : scoreValues,
  answer: '',
}));

export const professionalQuestions = [
  `I feel satisfied with my work and career progression.`,
  `I feel stressed or burned out due to work.`,
  `My job aligns with my skills, values, and interests.`,
  `I have a healthy work-life balance.`,
  `I feel valued and appreciated at work. `,
].map((question, index) => ({
  question,
  answerOption: index === 1 ? reverseScores : scoreValues,
  answer: '',
}));

export const physcialQuestions = [
  `I engage in at least 30 minutes of physical activity most days.`,
  `I eat a balanced diet with sufficient fruits and vegetables.`,
  `I feel energetic and physically active throughout the day.`,
  `I avoid excessive alcohol, tobacco, or substance use.`,
  `I visit a doctor for regular health check-ups.`,
].map((question, index) => ({
  question,
  answerOption: scoreValues,
  answer: '',
}));

export const socialQuestions = [
  `I feel connected to a strong support system of friends or family.`,
  `I have meaningful conversations and social interactions regularly.`,
  `I often feel lonely or isolated.`,
  `I engage in social activities that bring me joy.`,
  `I feel like I belong to a community or social group.`,
].map((question, index) => ({
  question,
  answerOption: index === 2 ? reverseScores : scoreValues,
  answer: '',
}));

const scoreCategories = {
  emotionalWellnessScoring: {
    low: {
      range: [5, 12],
      description: 'High stress, poor emotional resilience.',
    },
    moderate: {
      range: [13, 19],
      description: 'Some emotional balance but needs improvement.',
    },
    excellent: {
      range: [20, 25],
      description: 'Strong emotional well-being, good stress management.',
    },
  },
  financialWellnessScoring: {
    low: {range: [5, 12], description: 'High financial stress, poor savings.'},
    moderate: {
      range: [13, 19],
      description: 'Some financial stability, occasional worries.',
    },
    excellent: {
      range: [20, 25],
      description: 'Strong financial security, good financial planning.',
    },
  },
  sleepWellnessScoring: {
    low: {
      range: [5, 12],
      description: 'Poor sleep quality, insomnia symptoms.',
    },
    moderate: {
      range: [13, 19],
      description: 'Some sleep disturbances, inconsistent routine.',
    },
    excellent: {
      range: [20, 25],
      description: 'Regular restful sleep, high sleep quality.',
    },
  },
  professionalWellnessScoring: {
    low: {
      range: [5, 12],
      description: 'High work stress, burnout, low job satisfaction.',
    },
    moderate: {
      range: [13, 19],
      description: 'Some job satisfaction, occasional burnout.',
    },
    excellent: {
      range: [20, 25],
      description: 'High job fulfillment, work-life balance.',
    },
  },
  physicalWellnessScoring: {
    low: {
      range: [5, 12],
      description: 'Poor health habits, lack of physical activity.',
    },
    moderate: {
      range: [13, 19],
      description: 'Some healthy habits, needs improvement.',
    },
    excellent: {
      range: [20, 25],
      description: 'Active lifestyle, good physical health.',
    },
  },
  socialWellnessScoring: {
    low: {range: [5, 12], description: 'Social isolation, lack of support.'},
    moderate: {
      range: [13, 19],
      description: 'Some social connection, occasional loneliness.',
    },
    excellent: {
      range: [20, 25],
      description: 'Strong support network, meaningful relationships.',
    },
  },
};

export const getWellnessCategory = (score, scoreKey) => {
  const scoringObject = scoreCategories[scoreKey];
  if (!scoringObject || typeof scoringObject !== 'object') {
    return {
      scoreLabel: 'Invalid Data',
      description: 'Scoring object is missing or incorrect.',
    };
  }

  for (const [scoreLabel, value] of Object.entries(scoringObject)) {
    if (
      !value.range ||
      !Array.isArray(value.range) ||
      value.range.length !== 2
    ) {
      continue; // Skip if invalid structure
    }

    const [min, max] = value.range;
    if (score >= min && score <= max) {
      return {scoreLabel, description: value.description};
    }
  }

  return {scoreLabel: 'Invalid Score', description: 'Score is out of range.'};
};

export const sumScores = arr => {
  return arr.reduce((acc, val) => {
    return acc + val.answer;
  }, 0);
};
