import { useEffect, useState } from "react";
import { postapi } from "../../Helpers/Api";

interface ApiData {
  url: string;
  data: any;
}

interface ApiResponse {
  status: string;
  data: any;
}

const useApiData = ({ url, data }: ApiData) => {
  const [loading, setLoading] = useState(true); // Initialize as true
  const [apidata, setApiData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null); // Add error state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);


        const response: ApiResponse = await postapi(url, data);

        if (response.status === "success") {
          setApiData(response.data);
        } else {
          setError("Failed to fetch data"); // Handle API-specific errors
        }
      } catch (err) {
        setError("An error occurred while fetching data"); // Handle network/other errors
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, data]); // Re-fetch if url or data changes

  return { loading, apidata, error }; // Return error state for better error handling
};

export default useApiData;