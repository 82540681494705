import React, { Component } from "react";
import { CDN_URL, REACT_APP_API_URL } from "../../processENV";
import forarr from "../../../assets/images/forarr.png";
import { IoCaretForward } from "react-icons/io5";
import { withRouter } from "react-router-dom";

export class ChatDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
    };
  }
  componentDidMount() {
    this.getChat();
    this.getName();
  }

  getName = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/patientedit_app/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          this.setState({ firstName: res.data.first_name });
        } else {
          console.log("meeting id", res.data);
        }
      });
  };
  getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/app_chathome/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          this.setState({ item: res });
        } else {
          console.log("meeting id", res.data);
        }
      });
  };

  async joinFreeChat() {
    const pt_key = localStorage._patientKey;
    const JsonData = {
      patient_n_key: localStorage._patientKey,
    };
    fetch(REACT_APP_API_URL + "/counselling_room/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === "success") {
          let JsonData1 = {
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(JsonData1),
          })
            .then((response) => response.json())
            .then(async (resp) => {
              if (resp.status == "success") {
                localStorage.uid = pt_key;
                localStorage.channelName = res.channelid;
                this.props.history.push("/chatscreen", {
                  channelId: res.channelid,
                  token: resp.rtm_data,
                  uid: pt_key,
                  typeofchat: "CounsellingRoom",
                  freeChat: res,
                  firstChat: res.new_user,
                  doctor_photo:
                    this.state.item &&
                    this.state.item?.support_chat &&
                    this.state.item?.support_chat.length !== 0
                      ? this.state.item?.support_chat[0]?.doctor_photo
                      : null,
                  doctor_name:
                    this.state.item &&
                    this.state.item?.support_chat &&
                    this.state.item?.support_chat.length !== 0
                      ? this.state.item.support_chat[0]?.doctor_name
                      : null,
                  disable:
                    this.state.item &&
                    this.state.item?.support_chat &&
                    this.state.item?.support_chat.length !== 0
                      ? this.state.item.support_chat[0]?.expired
                      : false,
                });
              }
            });
        }
      });
  }
  async joinTherapyChat() {
    let JsonData = {
      channelName: "Addon",
      uid: localStorage._patientKey, //'test456',
    };
    fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.status == "success") {
          localStorage.uid = localStorage._patientKey;
          localStorage.channelName =
            this.state.item &&
            this.state.item?.therapy_chat &&
            this.state.item?.therapy_chat.length !== 0 &&
            this.state.item?.therapy_chat[0]?.channelid;
          this.props.history.push("/chatscreen", {
            channelId:
              this.state.item &&
              this.state.item?.therapy_chat &&
              this.state.item?.therapy_chat.length !== 0 &&
              this.state.item?.therapy_chat[0]?.channelid,
            token: response.rtm_data,
            uid: localStorage._patientKey,
            typeofchat: "Addon",
            disable:
              this.state.item &&
              this.state.item?.therapy_chat &&
              this.state.item?.therapy_chat.length !== 0
                ? this.state.item?.therapy_chat[0]?.expired
                : false,
            doctor_photo:
              this.state.item &&
              this.state.item?.therapy_chat &&
              this.state.item?.therapy_chat.length !== 0
                ? this.state?.item.therapy_chat[0]?.doctor_photo
                : null,
            doctor_name:
              this.state.item &&
              this.state.item?.therapy_chat &&
              this.state.item?.therapy_chat.length !== 0
                ? this.state.item?.therapy_chat[0]?.doctor_name
                : null,
          });
        }
      });
  }
  async generateCareRoomAgoraToken() {
    let JsonData = {
      channelName: "CareRoom",
      uid: localStorage._patientKey, //'test456',
    };
    fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.status == "success") {
          localStorage.uid = localStorage._patientKey;
          localStorage.channelName = localStorage._patientKey;
          this.props.history.push("/chatscreen", {
            channelId: localStorage._patientKey,
            empKey: "ORG1-EMP-1",
            token: response.rtm_data,
            uid: localStorage._patientKey,
            typeofchat: "CareRoom",
            doctor_photo:
              this.state.item &&
              this.state.item?.admin_chat &&
              this.state.item?.admin_chat.length !== 0
                ? this.state?.item?.admin_chat[0]?.doctor_photo
                : null,
            doctor_name:
              this.state.item &&
              this.state.item?.admin_chat &&
              this.state.item?.admin_chat.length !== 0
                ? this.state?.item?.admin_chat[0]?.doctor_name
                : null,
            back: "chatDetails",
            disable: false,
          });
        }
      });
  }

  render() {
    const { item, firstName } = this.state;

    return (
      <div className="container" style={{height:'100vh'}}>
        <div className="row  mx-auto text-center">
          <div className="col-md-8 mx-auto pl-md-5 mt-md-5 mt-4">
            <button
              onClick={() => (window.location.href = "/offerings")}
              style={{ ursor: "pointer",border:'none'}}
              className="col-md-8 p-2 mt-md-5 secondColor borderColor mt-5  mx-auto row rounded d-flex flex-row align-items-center justify-content-between"
            >
              <p style={{ fontSize: "20px", color: "#215352" }} className="text-center pl-4 py-0 my-0" >
                Book a sesssion
              </p>
              <p className="text-center pr-4 pt-3" style={{ right: 30 }}>
                <IoCaretForward color="#215352" size={30} />
              </p>
            </button>

            {item && item?.therapy_chat && item?.therapy_chat?.length !== 0 ? (
              <>
                <div
                  style={{ backgroundColor: "#efefee" }}
                  className="col-md-8 row pt-5 mt-5 py-3 mx-auto d-flex flex-row justify-content-between"
                  onClick={() => this.joinTherapyChat()}
                >
                  <img
                    className="rounded-circle cht_img"
                    src={
                      item && item?.therapy_chat?.length !== 0
                        ? item.therapy_chat[0].doctor_photo
                        : null
                    }
                    resizeMode="cover"
                  />

                  {item &&
                  item.therapy_chat &&
                  item.therapy_chat.length !== 0 &&
                  item.therapy_chat[0].unseen_count !== 0 ? (
                    <div
                      style={{
                        backgroundColor: "#4266f5",
                        position: "absolute",
                        borderRadius: 50,
                        width: 25,
                        height: 25,
                        right: 8,
                        top: 3,
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontWeight: "bold",
                          padding: 5,
                        }}
                      >
                        {item.therapy_chat && item.therapy_chat[0].unseen_count}
                      </span>
                    </div>
                  ) : null}

                  <p className="_cht_text">
                    Chat with your therapist
                    <br />
                    Hi {firstName}, a single text <br />
                    can make a heart ❤️ open
                  </p>
                  <img src={forarr} className="cht_img_" />
                </div>
              </>
            ) : null}

            <div
              style={{ backgroundColor: "#efefee" }}
              className="col-md-8 mx-auto py-md-4 mt-5 py-3  borderColor rounded d-flex text-center flex-row justify-content-between"
              onClick={() => {
                this.joinFreeChat();
              }}
            >
              {item &&
              item.support_chat &&
              item?.support_chat[0]?.doctor_photo ? (
                <img
                  className="rounded-circle cht_img"
                  src={item?.support_chat[0]?.doctor_photo}
                />
              ) : (
                <img
                  className="rounded-circle cht_img"
                  src={
                   `${CDN_URL}/Profile/web%20icon%202%20copy.png`
                  }
                />
              )}
              <p className="_cht_text">
                Chat with care coach
                <br />
                Feel supported, any time of the day or night
              </p>
              <img src={forarr} className="cht_img_" />
            </div>

            <div
              style={{ backgroundColor: "#efefee" }}
              className="col-md-8 row py-md-4 py-3 mt-5 borderColor rounded mx-auto d-flex flex-row  justify-content-between"
              onClick={() => this.generateCareRoomAgoraToken()}
            >
              {item && item.admin_chat && item?.admin_chat[0]?.doctor_photo ? (
                <img
                  className="rounded-circle cht_img"
                  src={item?.admin_chat[0]?.doctor_photo}
                  resizeMode="cover"
                />
              ) : (
                <img
                  className="rounded-circle cht_img"
                  src={
                   `${CDN_URL}/Profile/web%20icon1%20copy.png`
                  }
                  resizeMode="cover"
                />
              )}

              <p className="_cht_text">
                Admin
                <br />
                Having trouble with appointment schedule
                <br /> /payments our care executive can help.
              </p>

              <img className="cht_img_" src={forarr} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChatDetails);
