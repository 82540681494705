import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const meta = [
  {
    emoji: '🌱',
    title: 'Just Starting',
  },
  {
    emoji: '🚀',
    title: 'Steady Progress',
    recommended: true,
  },
  {
    emoji: '🌟',
    title: 'All In',
  },
];

const SessionPlans = ({ plans = [],onClickCard }) => {
  if (!plans.length) return null;

  // Attach emoji/title/recommended to each plan
  const fullPlans = plans.map((plan, index) => ({
    ...plan,
    ...meta[index],
  }));

  const upperRow = fullPlans.filter(p => !p.recommended);
  const recommendedPlan = fullPlans.find(p => p.recommended);

  const renderCard = (plan, index) => (
    <div
      key={index}
      className={`card text-center m-3 borderColor roundedBorder secondColor`}
      style={{
        width: '100%',
        minHeight:'200px',
        maxWidth: '200px',
        borderRadius: '16px',
        boxShadow: '0 0 0 1px #ccc',
        position: 'relative',
      }}
    >
      {plan.recommended && (
        <span
          className="badge bg-success"
          style={{
            position: 'absolute',
            top: '-5px',
            left: '65%',
            transform: 'translateX(-50%)',
            borderRadius: '10px',
            fontSize: '12px',
            padding: '4px 10px',
            color:'white'
          }}
        >
          Recommended
        </span>
      )}
      <h6 className="font-weight-bold h5 text_p_ mt-4 pt-3">{plan.emoji} {plan.title}</h6>
      <p className="mb-1 pt-2 h6">{plan.no_of_session} Session{plan.no_of_session > 1 ? 's' : ''} at</p>
      <h5 className="h2 pt-2 text-decoration-none font-weight-bolder">₹{parseFloat(plan.amount)}</h5>
      <p className="text-muted mb-2 py-2" style={{ fontSize: '14px' }}>(Save ₹{parseFloat(plan.discount_amount).toLocaleString()})</p>
      <button onClick={()=>onClickCard(plan.amount,plan.no_of_session,plan)} className="btn newbtn py-2 mt-auto" style={{fontSize:'18px', borderBottomWidth:0,borderLeftWidth:0,borderRightWidth:0,borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}} >
        Book Now
      </button>
    </div>
  );

  return (
    <div className="container text-center py-4">
      {/* Upper Row */}
      <div className="d-flex flex-wrap justify-content-center gap-12">
        {upperRow.map(renderCard)}
      </div>

      {/* Recommended Plan */}
      {recommendedPlan && (
        <div className="d-flex justify-content-center mt-4">
          {renderCard(recommendedPlan, 99)}
        </div>
      )}
    </div>
  );
};

export default SessionPlans;
