
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopQuestion from "./TopQuestion";
import InitialLoader from "./InitialLoader";
import useApiData from "./useApiData";
import {
  FaMale,
  FaFemale,
  FaSmile,
  FaWeixin,
  FaPhoneSquare,
  FaVideo,
} from "react-icons/fa";

const TherapyComponent = ({
  endPoint,
  dataKey,
  accessKey,
  headerQuestion,
  subHeader,
  labelKey,
  moveToNext,
  appointmentDetails,
  productDetails,
  setAppointmentDetails,
  currentStep,
  patient_n_key,
}) => {


  const postdataObject = {patient_n_key};
  console.log(appointmentDetails,productDetails)
  if (dataKey === "therapy_type_id") {
    postdataObject.app_prod_grp_key = productDetails?.app_prod_grp_key;
  } else if (dataKey === "language") {
    postdataObject.app_prod_grp_key = productDetails?.app_prod_grp_key;
    postdataObject.therapy_type_id = appointmentDetails?.therapy_type_id;
  } else if (dataKey === "gender") {
    postdataObject.app_prod_grp_key = productDetails?.app_prod_grp_key;
    postdataObject.therapy_type_id = appointmentDetails?.therapy_type_id;
    postdataObject.language = appointmentDetails?.language;
  } else if (dataKey === "mode") {
    postdataObject.app_prod_grp_key = productDetails?.app_prod_grp_key;
    postdataObject.therapy_type_id = appointmentDetails?.therapy_type_id;
    postdataObject.language = appointmentDetails?.language;
    postdataObject.gender = appointmentDetails?.gender;
  } else if (dataKey === "date") {
    postdataObject.app_prod_grp_key = productDetails?.app_prod_grp_key;
    postdataObject.therapy_type_id = appointmentDetails?.therapy_type_id;
    postdataObject.language = appointmentDetails?.language;
    postdataObject.gender = appointmentDetails?.gender;
    postdataObject.employee_n_key = appointmentDetails?.employee_n_key;
  } else if (dataKey === "appoint_time") {
    postdataObject.app_prod_grp_key = productDetails?.app_prod_grp_key;
    postdataObject.therapy_type_id = appointmentDetails?.therapy_type_id;
    postdataObject.language = appointmentDetails?.language;
    postdataObject.gender = appointmentDetails?.gender;
    postdataObject.employee_n_key = appointmentDetails?.employee_n_key;
    postdataObject.rescheduled_date = appointmentDetails?.date;
  }

  const postdata = Object.keys(postdataObject).length
    ? JSON.stringify(postdataObject)
    : null;

  const { loading, apidata = [], error } = useApiData({
    url: endPoint,
    data: postdata,
  });

  const handleSelect = (selectedKey) => {
    console.log(accessKey,selectedKey)
    setAppointmentDetails((prev) => ({
      ...prev,
      [accessKey || dataKey]: selectedKey,
    }));
    moveToNext();
  };

  const getButtonClass = (selectedKey) =>
    selectedKey === appointmentDetails[(accessKey || dataKey)]
      ? "btn newbtn"
      : "btn borderColor fontColor secondColor ";

   const RenderIcon = ({ value, dataKey }) => {
        if (dataKey === "gender") {
          if (value === "Male") return <FaMale size={20} style={{ marginRight: 5 }} />;
          if (value === "Female") return <FaFemale size={20} style={{ marginRight: 5 }} />;
          return <FaSmile size={20} style={{ marginRight: 5 }} />;
        } else if (dataKey === "mode") {
          if (value === "Chat") return <FaWeixin size={20} style={{ marginRight: 5 }} />;
          if (value === "Audio") return <FaPhoneSquare size={20} style={{ marginRight: 5 }} />;
          return <FaVideo size={20} style={{ marginRight: 5 }} />;
        } else {
          return null;
        }
      };
  return (
    <div className="container px-0 px-md-3 py-3 overflow-auto">
      {loading || error || apidata.length === 0 ? (
        <InitialLoader loading={loading} error={error} apidata={apidata} />
      ) : (
        <>
          <TopQuestion headerQuestion={headerQuestion} subHeader={subHeader} />

          <div className="row justify-content-center px-3 px-md-0">
            <div className="col-md-8">
              <div className="d-flex flex-column gap-3">
                {apidata?.map((item) => (
                  <button
                    key={labelKey ? item[labelKey] : item}
                    onClick={() => handleSelect(accessKey ? item[accessKey] : item)}
                    style={{fontSize:'16px'}}
                    className={`text-left px-4 py-2 my-2 ${getButtonClass(accessKey ? item[accessKey] : item)}`}
                  >
                      <RenderIcon value = {
                labelKey && item[labelKey]
                  ? item[labelKey].toString()
                  : typeof item === "string" || typeof item === "number"
                  ? item.toString()
                  : JSON.stringify(item)} />
                    {labelKey ? item[labelKey] : item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(TherapyComponent);
