import { forwardRef, useImperativeHandle, useState } from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import { postapi } from "../../Helpers/Api";

const AppointmentConfirmation = forwardRef(({ productDetails, appointmentDetails }, ref) => {
  const {
    language,
    mode,
    pt_key,
    date,
    gender,
    employee_n_key,
    appoint_time,
    app_payment_n_key,
    add_on,
  } = appointmentDetails;
  const {
    amount, title, discount, discount_amount,
    app_prod_n_key, product_amount, app_prod_grp_key
  } = productDetails;

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const appointmentConfirm = async () => {
    setLoading(true);
    const patient_n_key =localStorage?._patientKey;
    try {
      const data = JSON.stringify({
        patient_n_key,
        preferred_language: language || "English",
        appointment_date: date,
        appointment_time: appoint_time,
        product: title || '',
        tax: "0",
        tax_amount: "0",
        status: "Paid",
        paid_amount: amount,
        app_prod_n_key: app_prod_n_key || "APP_PROD-9",
        sale_price: product_amount,
        app_payment_n_key,
        product_amount,
        total_session: title === "Couple Therapy" ? 2 : 1,
        addon: add_on,
        chief_complaints: text,
        discount,
        discount_amount,
        therapist_gender: gender,
        mode_of_telehealth: mode || "Video",
        app_prod_grp_key,
        patient2: pt_key || null,
        doc_app_id: employee_n_key,
      });

      const res = await postapi("/careteam_book_appointment/", data);
      if (res.status === "success") {
        setLoading(false);
        window.location.href="/home";
      } else {
        setLoading(false);
        Alert("Error", "Something went wrong, please try again.");
      }
    } catch (error) {
      setLoading(false);
      Alert("Error", "Unable to process the appointment.");
    }
  };

  useImperativeHandle(ref, () => ({ appointmentConfirm }));

  return (
    <div className="container col-md-6 mx-auto py-4">
      {loading && (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <h3 className="fontColor font-weight-bold text-left h4 mb-2">You're All Set! ✅</h3>
      <p className="fontColor text-left h6 text-muted mb-0">
        Review your appointment details before confirming. Your journey to feeling better starts here.
      </p>

      <div className="mt-4 mb-3">
        <DetailItem label="Language" value={language} icon="🌐" />
        <DetailItem label="Session Type" value={mode} icon="🎥" />
        <DetailItem label="Date" value={date} icon="📅" />
        <DetailItem label="Time" value={appoint_time} icon="⏰" />
      </div>

      <p className="fontColor text-left">✍️ Want to share anything with your therapist before the session? (Optional)</p>

      <Form.Group className="mb-3">
        <Form.Control
          style={{fontSize:'15px',minHeight:'120px'}}
          as="textarea"
          rows={5}
          placeholder="Consider sharing a bit about what you'd like to focus on, if you have prior experience with therapy, or if you're looking for a specific type of care."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Form.Group>

      {/* You can uncomment the button below to allow manual confirmation */}
      {/* <Button variant="primary" onClick={appointmentConfirm}>Confirm Appointment</Button> */}
    </div>
  );
});

const DetailItem = ({ label, value, icon }) => (
  <div style={{fontSize:'18px'}} className="d-flex align-items-center border-bottom border-dashed py-4">
    <span className="mr-3">{icon}</span>
    <strong className="mr-1">{label}:</strong>
    <span>{value}</span>
  </div>
);

export default AppointmentConfirmation;
