import Skeleton from "./Skeleton";

interface LoaderProps {
  error: string | null;
  loading: boolean;
  apidata: any[];
}

const InitialLoader = ({ error, loading, apidata }: LoaderProps) => {
  if (loading) return <Skeleton />;

  if (error)
    return (
      <p className="text-danger text-center fw-medium">
        Error: {error}
      </p>
    );

  if (apidata.length === 0)
    return (
      <p className="text-secondary text-center fw-medium">
        No therapy types available at the moment.
      </p>
    );

  return null;
};

export default InitialLoader;
