import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Circle, PhoneCall } from 'react-feather';
import { BsCheck2Circle, BsCircleFill, BsWhatsapp } from 'react-icons/bs';
import { Flash } from "react-ionicons";
import { CDN_URL, REACT_APP_API_URL } from '../processENV';
import Caremeloader from './Caremeloader';
import FullScreenConfetti from './Confetti';
import AssessmentPage from '../wellbeing/WellbeingAsses';

const stepsData = [
  { label: "Welcome", range: [0, 1] },
  { label: "Emotional", range: [1, 3] },
  { label: "Financial", range: [3, 5] },
  { label: "Sleep", range: [5, 7] },
  { label: "Professional", range: [7, 9] },
  { label: "Physical", range: [9, 11] },
  { label: "Social", range: [11, 13] },
];
const StepIndicator = ({ step, label, range,isLast }) => {
  const [start, end] = range;
  const isCurrent = step >= start && step < end;
  const isCompleted = step >= end;

  const opacity = isCurrent || isCompleted ? 1 : 0.5;

  const renderIcon = () => {
    if (isCurrent) return <Circle size={14} />;
    if (isCompleted) return <BsCheck2Circle />;
    return <BsCircleFill size={12} />;
  };

  return (
    <>
      <p style={{ opacity }} className="text-white d-flex align-items-center">
        <span className="mr-2">{renderIcon()}</span> {label}
      </p>
      {!isLast && <div
        className="mb-1"
        style={{
          width: "3px",
          marginLeft: "5px",
          height: "12px",
          opacity,
          background: "#fff",
          borderRadius: "10px",
        }}
      ></div>}
    </>
  );
};

const RoundProgressBar = ({ answeredCount, questionCount, radius, strokeWidth, color }) => {
  const progress = answeredCount / questionCount;
  const circumference = 2 * Math.PI * radius;
  const progressStroke = progress * circumference;
  const remainingStroke = circumference - progressStroke;
  const progressColor = progress >= 0.5 ? "green" : "orange"; // Update the color based on the progress

  return (
    <div>
      <svg width={radius * 2 + strokeWidth} height={radius * 2 + strokeWidth}>
        <circle
          cx={radius + strokeWidth / 2}
          cy={radius + strokeWidth / 2}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth/4}
          fill="none"
        />
        <path
          stroke={progressColor}
          strokeWidth={strokeWidth/4}
          strokeDasharray={[progressStroke, remainingStroke]}
          d={`M ${radius + strokeWidth / 2}, ${strokeWidth / 2} A ${radius}, ${radius} 0 ${progress > 0.5 ? 1 : 0}, 1 ${radius + strokeWidth / 2}, ${radius * 2 + strokeWidth / 2} A ${radius}, ${radius} 0 ${progress > 0.5 ? 1 : 0}, 1 ${radius + strokeWidth / 2}, ${strokeWidth / 2}`}
          fill="none"
        />
         <text x={radius + strokeWidth / 2} y={radius + strokeWidth / 2}  className='font-weight-bold' fontSize={12} fill="#000" textAnchor="middle"  alignmentBaseline="middle">
               {answeredCount} / {questionCount}
      </text>
      </svg>
    </div>
  );
};

 class wellbeing extends Component {
  constructor(props) {
    super(props);
    this.myButtonRef = React.createRef();
    this.topRef = React.createRef();
    this.state = {
      profile_: "",
      step: 1,
      disable: false,
      quesObj: [],
      bulkData: {},
      indx: 0,
      userinfo: {},
      emotionalscore_: "",
      socialscore_: "",
      physicalscore_: "",
      personalscore_: "",
      professionalscore_: "",
      financialscore_: "",
      emotionalscale_: "",
      socialscale_: "",
      physicalscale_: "",
      personalscale_: "",
      professionalscale_: "",
      financialscale_: "",
      showresultmodal: false,
      showLoader: true,
      showcofetti: false,
      emotional_: [
        { value: "Improve awareness", selected: false },
        { value: "Healthy coping", selected: false },
        { value: "Enhance intelligence", selected: false },
        { value: "Strengthen resilience", selected: false },
        { value: "Positive environment", selected: false },
        { value: "Effective communication", selected: false },
        { value: "Healthy boundaries", selected: false },
        { value: "Increase empathy", selected: false },
        { value: "None of the above ", selected: false },
      ],
      social_: [
        { value: "Expand social circle", selected: false },
        { value: "Strengthen relationships", selected: false },
        { value: "Effective communication", selected: false },
        { value: "More social activities", selected: false },
        { value: "Cultivate support network", selected: false },
        { value: "Conflict resolution", selected: false },
        { value: "Make new friends", selected: false },
        { value: "Balance personal/social", selected: false },
        { value: "None of the above", selected: false },
      ],
      set1: [
        {
          question:
            "When I want to feel more positive emotion (such as joy or amusement), I change what I’m thinking about",
          answer: "",
        },
        {
          question:
            "When I want to feel less negative emotion (such as sadness or anger), I change what I’m thinking about ",
          answer: "",
        },
        {
          question:
            "When I’m faced with a stressful situation, I make myself think about it in a way that helps me stay calm ",
          answer: "",
        },
        {
          question:
            "When I want to feel more positive emotion, I change the way I’m thinking about the situation ",
          answer: "",
        },
        {
          question:
            "I control my emotions by changing the way I think about the situation I’m in ",
          answer: "",
        },
        {
          question:
            "When I want to feel less negative emotion, I change the way I’m thinking about the situation ",
          answer: "",
        },
        { question: "I keep my emotions to myself ", answer: "" },
        {
          question:
            "When I am feeling positive emotions, I am careful not to express them ",
          answer: "",
        },
        {
          question: "I control my emotions by not expressing them ",
          answer: "",
        },
        {
          question:
            "When I am feeling negative emotions, I make sure not to express them ",
          answer: "",
        },
      ],
      indx1: 0,
      ans1: [
        { value: "Strongly disagree", selected: false, scale: 1 },
        { value: "Moderately disagree", selected: false, scale: 2 },
        { value: "Slightly disagree", selected: false, scale: 3 },
        { value: "Neither agree nor disagree", selected: false, scale: 4 },
        { value: "Slightly agree", selected: false, scale: 5 },
        { value: "Moderately agree", selected: false, scale: 6 },
        { value: "Strongly agree", selected: false, scale: 7 },
      ],
      set2: [
        {
          question: "I eat well balanced meals",
          answer: "",
          answerOption: [
            { value: "Everyday", selected: false, scale: 7 },
            { value: "Weekdays only", selected: false, scale: 5 },
            { value: "Thirce a week", selected: false, scale: 4 },
            { value: "Weekends only", selected: false, scale: 3 },
            { value: "Once weekly", selected: false, scale: 2 },
            { value: "Rarely", selected: false, scale: 1 },
            { value: "Not at all", selected: false, scale: 0 },
          ],
        },
        {
          question: "I exercise",
          answer: "",
          answerOption: [
            { value: "Everyday", selected: false, scale: 7 },
            { value: "Weekdays Only", selected: false, scale: 5 },
            { value: "Thrice a week", selected: false, scale: 4 },
            { value: "Weekends only", selected: false, scale: 3 },
            { value: "Once weekly", selected: false, scale: 2 },
            { value: "Rarely", selected: false, scale: 6 },
            { value: "Not at all", selected: false, scale: 0 },
          ],
        },
        {
          question: "I have medical conditions",
          answer: "",
          answerOption: [
            { value: "Yes", selected: false, scale: 0 },
            { value: "Mild conditions", selected: false, scale: 1 },
            { value: "No", selected: false, scale: 2 },
          ],
        },
        {
          question: "My screen time daily is usually",
          answer: "",
          answerOption: [
            { value: "1-2 hrs", selected: false, scale: 7 },
            { value: "3-4 hrs", selected: false, scale: 5 },
            { value: "5-6 hrs", selected: false, scale: 4 },
            { value: "7-8 hrs", selected: false, scale: 3 },
            { value: "9-10 hrs", selected: false, scale: 2 },
            { value: "upto 15 hrs", selected: false, scale: 1 },
            { value: "15+ hrs", selected: false, scale: 0 },
          ],
        },
        {
          question: "Usual sleep pattern",
          answer: "",
          answerOption: [
            { value: "Once in 2 days", selected: false, scale: 0 },
            { value: "3-4 hrs", selected: false, scale: 1 },
            { value: "7-8 hrs", selected: false, scale: 2 },
            { value: "10+ hrs", selected: false, scale: 3 },
          ],
        },
        {
          question: "I feel tired and experience aches and pains",
          answer: "",
          answerOption: [
            { value: "Always", selected: false, scale: 0 },
            { value: "Mostly", selected: false, scale: 1 },
            { value: "Usually", selected: false, scale: 2 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Rarely", selected: false, scale: 4 },
            { value: "Ocasionally", selected: false, scale: 5 },
            { value: "Never", selected: false, scale: 6 },
          ],
        },
        {
          question: "I feel motivated to keep fit",
          answer: "",
          answerOption: [
            { value: "Always", selected: false, scale: 6 },
            { value: "Mostly", selected: false, scale: 5 },
            { value: "Usually", selected: false, scale: 4 },
            { value: "Sometimes", selected: false, scale: 3 },
            { value: "Rarely", selected: false, scale: 2 },
            { value: "Ocasionally", selected: false, scale: 1 },
            { value: "Never", selected: false, scale: 0 },
          ],
        },
      ],
      indx2: 0,
      personal_: [
        { value: "Improve awareness", selected: false },
        { value: "Boost self-esteem", selected: false },
        { value: "Positive mindset", selected: false },
        { value: "Time management", selected: false },
        { value: "Find purpose", selected: false },
        { value: "Strengthen relationships", selected: false },
        { value: "Pursue joy", selected: false },
        { value: "Self-improvement", selected: false },
        { value: "None of the above", selected: false },
      ],
      set3: [
        { question: "I take a positive attitude toward myself ", answer: "" },
        {
          question: "Overall‚ I am satisfied with myself and my life ",
          answer: "",
        },
        { question: "I feel upset often since I left my hometown", answer: "" },
        {
          question:
            "I feel more discouraged about my future than I used to be ",
          answer: "",
        },
        { question: "I often feel like I am a failure ", answer: "" },
        { question: "I am not as confident as I used to be ", answer: "" },
        {
          question: "I don’t consider myself as worthwhile and useful ",
          answer: "",
        },
        {
          question: "I can’t concentrate as well as I usually could ",
          answer: "",
        },
        { question: "I am more irritable than usual ", answer: "" },
        {
          question:
            "Sometimes I feel I am treated differently in an uncomfortable way because I am from a different city/country ",
          answer: "",
        },
        { question: "I feel homesick quite often ", answer: "" },
        {
          question:
            "I feel uncomfortable to adjust myself to the local cultural environment ",
          answer: "",
        },
        { question: "I often feel lonely and isolated ", answer: "" },
      ],
      ans3: [
        { value: "Strongly disagree", selected: false, scale: 1 },
        { value: "Moderately disagree", selected: false, scale: 2 },
        { value: "Slightly disagree", selected: false, scale: 3 },
        { value: "Neither agree nor disagree", selected: false, scale: 4 },
        { value: "Slightly agree", selected: false, scale: 5 },
        { value: "Moderately agree", selected: false, scale: 6 },
        { value: "Strongly agree", selected: false, scale: 7 },
      ],
      indx3: 0,
      physical_: [
        { value: "Improve exercise", selected: false },
        { value: "Balanced diet", selected: false },
        { value: "Enhance sleep", selected: false },
        { value: "Manage stress", selected: false },
        { value: "Consistent self-care", selected: false },
        { value: "Maintain weight", selected: false },
        { value: "Address health issues", selected: false },
        { value: "Positive body image", selected: false },
        { value: "None of the above", selected: false },
      ],
      set4: [
        { question: "I make time for friends ", answer: "" },
        { question: "I make time for my family ", answer: "" },
        { question: "I make time for hobbies ", answer: "" },
        {
          question:
            "I spend time on activities with others that I maynot enjoy myself ",
          answer: "",
        },
      ],
      // ans4 :[{value:"Strongly Agree",selected:false,scale:1}, {value:"Agree",selected:false,scale:3}, {value:"Neutral",selected:false,scale:5}, {value:"Disagree",selected:false,scale:8}, {value:"Strongly Disagree",selected:false,scale:10}],
      indx4: 0,
      professional_: [
        { value: "Enhance satisfaction", selected: false },
        { value: "Balance work-life", selected: false },
        { value: "Strengthen relationships", selected: false },
        { value: "Increase productivity", selected: false },
        { value: "Develop leadership", selected: false },
        { value: "Pursue growth", selected: false },
        { value: "Find meaning", selected: false },
        { value: "Manage stress", selected: false },
        { value: "None of the above", selected: false },
      ],
      set5: [
        { question: "I am able to finish my tasks  ", answer: "" },
        { question: "I take initiatives ", answer: "" },
        { question: "I interact intentionally at work ", answer: "" },
        {
          question: "I am focused on growth and development of my career ",
          answer: "",
        },
        {
          question: "I am focused on growth and development of my company  ",
          answer: "",
        },
      ],
      ans4: [
        { value: "Always", selected: false, scale: 1 },
        { value: "Mostly", selected: false, scale: 2 },
        { value: "Usually", selected: false, scale: 3 },
        { value: "Sometimes", selected: false, scale: 4 },
        { value: "Rarely", selected: false, scale: 5 },
        { value: "Occasionally", selected: false, scale: 6 },
        { value: "Never", selected: false, scale: 7 },
      ],
      ans5: [
        { value: "Always", selected: false, scale: 1 },
        { value: "Mostly", selected: false, scale: 2 },
        { value: "Usually", selected: false, scale: 3 },
        { value: "Sometimes", selected: false, scale: 4 },
        { value: "Rarely", selected: false, scale: 5 },
        { value: "Occasionally", selected: false, scale: 6 },
        { value: "Never", selected: false, scale: 7 },
      ],
      indx5: 0,
      financial_: [
        { value: "Budgeting and adherence", selected: false },
        { value: "Saving and spending", selected: false },
        { value: "Achieving financial goals", selected: false },
        { value: "Debt management", selected: false },
        { value: "Retirement planning", selected: false },
        { value: "Financial literacy", selected: false },
        { value: "Healthy money relationship", selected: false },
        { value: "Stable income", selected: false },
        { value: "None of the above", selected: false },
      ],
      set6: [
        {
          question: "My income is sufficient to manage household expenditures ",
          answer: "",
          answerOption: [
            { value: "No", selected: false, scale: 0 },
            { value: "Sometimes", selected: false, scale: 1 },
            { value: "Yes", selected: false, scale: 2 },
          ],
        },
        {
          question: "My savings are __% of my earnings for the month ",
          answer: "",
          answerOption: [
            { value: "30", selected: false, scale: 1 },
            { value: "40", selected: false, scale: 2 },
            { value: "50", selected: false, scale: 3 },
            { value: "60", selected: false, scale: 4 },
            { value: "70", selected: false, scale: 5 },
            { value: "80", selected: false, scale: 6 },
            { value: "90", selected: false, scale: 7 },
          ],
        },
        {
          question: "I hold back on buying items of luxury ",
          answer: "",
          answerOption: [
            { value: "No", selected: false, scale: 0 },
            { value: "Sometimes", selected: false, scale: 1 },
            { value: "Yes", selected: false, scale: 2 },
          ],
        },
        {
          question: `I have paused purchase of items/services thinking I'll pursue it next month`,
          answer: "",
          answerOption: [
            { value: "No", selected: false, scale: 0 },
            { value: "Sometimes", selected: false, scale: 1 },
            { value: "Yes", selected: false, scale: 2 },
          ],
        },
        {
          question: "I can afford a holiday once a __",
          answer: "",
          answerOption: [
            { value: "Month", selected: false, scale: 6 },
            { value: "Quarter", selected: false, scale: 5 },
            { value: "6 Months", selected: false, scale: 4 },
            { value: "Year", selected: false, scale: 3 },
            { value: "2 Years", selected: false, scale: 2 },
            { value: "5 Years", selected: false, scale: 1 },
            { value: "Never", selected: false, scale: 0 },
          ],
        },
        {
          question: "The last out station trip I took with family was ____ago ",
          answer: "",
          answerOption: [
            { value: "Month", selected: false, scale: 6 },
            { value: "Quarter", selected: false, scale: 5 },
            { value: "6 Months", selected: false, scale: 4 },
            { value: "Year", selected: false, scale: 3 },
            { value: "2 Years", selected: false, scale: 2 },
            { value: "5 Years", selected: false, scale: 1 },
            { value: "Never", selected: false, scale: 0 },
          ],
        },
        {
          question: "I have invested in a retirement plan",
          answer: "",
          answerOption: [
            { value: "No", selected: false, scale: 0 },
            { value: "Not yet", selected: false, scale: 1 },
            { value: "Yes", selected: false, scale: 2 },
          ],
        },
      ],
      ans6: [
        { value: "Strongly Agree", selected: false, scale: 10 },
        { value: "Agree", selected: false, scale: 8 },
        { value: "Neutral", selected: false, scale: 5 },
        { value: "Disagree", selected: false, scale: 3 },
        { value: "Strongly Disagree", selected: false, scale: 1 },
      ],
      indx6: 0,
      overall_: [],
    };
  }

  componentDidMount() {
    this.getUser();
    setTimeout(()=>{
      this.setState({showLoader:false})
    },2000)
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    // };
    // axios.post(REACT_APP_API_URL+'/wellbeing_tracker/',{patient_n_key:localStorage._patientKey},{headers}).then((res)=>{
    //   console.log(res)
    //   if(res.data.status=='success'){
    //     this.setState({overall_:res.data.data,step:20})
    //   }
    // })
  }

  getUser = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(REACT_APP_API_URL + "/patientedit_app/", data, { headers })
      .then((res) => {
        console.log(res);
        console.log("patient_e", res);
        this.setState({ userinfo: res.data.data });
      });
  };
  handleuser = () => {
    if (localStorage._patientKey) {
      window.location.href = "/home";
    } else window.location.href = "https://app.careme.health/";
  };
  handleToggle = () => {
    this.setState({ showresultmodal: false });
  };
  handleForward = () => {
    const {step} = this.state;
    {
      this.setState({ step: step + 1 }, () => {
        if (
          step + 1 == 2 ||
          step + 1 == 5 ||
          step + 1 == 8 ||
          step + 1 == 11 ||
          step + 1 == 14 ||
          step + 1 == 17
        ) {
          this.setState({ disable: false });
        } else if (step + 1 == 20) {
          this.submitData_();
          this.setState({ showcofetti: true }, () => {
            setTimeout(() => {
              this.setState({ showcofetti: false, showresultmodal: true });
            }, 5000);
          });
        } else this.setState({ disable: true });
      });
    }
    this.topRef.current.scrollIntoView({ behavior: "smooth" });
    console.log(this.state.personalscore_);
  };
  handleCheck_ = async (index) => {
    const arr = [...this.state.emotional_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ emotional_: arr, disable: false });
  };
  handleCheck1_ = async (index) => {
    const arr = [...this.state.social_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ social_: arr, disable: false });
  };
  handleCheck2_ = async (index) => {
    const arr = [...this.state.personal_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ personal_: arr, disable: false });
  };
  handleCheck3_ = async (index) => {
    const arr = [...this.state.physical_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ physical_: arr, disable: false });
  };
  handleCheck4_ = async (index) => {
    const arr = [...this.state.professional_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ professional_: arr, disable: false });
  };
  handleCheck5_ = async (index) => {
    const arr = [...this.state.financial_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ financial_: arr, disable: false });
  };
  handleset1_ = async (index, inx) => {
    const arr = [...this.state.set1];
    arr[inx].answer = this.state.ans1[index].value;
    arr[inx].scale = this.state.ans1[index].scale;
    this.setState({ set1: arr }, () => {
      if (this.state.indx1 + 1 == this.state.set1.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx1: this.state.indx1 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    let cognitiveScore = 0;
    let expressiveScore = 0;
    // Loop through the response array
    for (let i = 0; i < filtervalue.length; i++) {
      const itemScore = filtervalue[i];

      // Check if the item is a cognitive reappraisal item
      if ([1, 2, 3, 4, 5, 6].includes(i + 1)) {
        cognitiveScore += itemScore;
      }

      // Check if the item is an expressive suppression item
      if ([7, 8, 9, 10].includes(i + 1)) {
        expressiveScore += itemScore;
      }
    }
    let score = cognitiveScore + expressiveScore;

    this.setState({ emotionalscale_: score });
    if (
      cognitiveScore >= 34 ||
      cognitiveScore <= 42 ||
      expressiveScore >= 21 ||
      expressiveScore <= 24
    ) {
      this.setState({ emotionalscore_: "Low" });
    } else if (
      cognitiveScore >= 13 ||
      cognitiveScore <= 33 ||
      expressiveScore >= 21 ||
      expressiveScore <= 24
    ) {
      this.setState({ emotionalscore_: "Moderate" });
    } else if (
      cognitiveScore <= 12 ||
      expressiveScore <= 4 ||
      expressiveScore >= 24
    ) {
      this.setState({ emotionalscore_: "Excellent" });
    }
  };
  handleset2_ = async (index, inx) => {
    const arr = [...this.state.set2];
    arr[inx].answer = arr[inx].answerOption[index].value;
    arr[inx].scale = arr[inx].answerOption[index].scale;
    this.setState({ set2: arr }, () => {
      if (this.state.indx2 + 1 == this.state.set2.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx2: this.state.indx2 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ physicalscale_: score });
    if (score && score <= 10) {
      this.setState({ physicalscore_: "Low" });
    } else if (score && score <= 17 && score >= 11) {
      this.setState({ physicalscore_: "Moderate" });
    } else if (score && score <= 29 && score >= 18) {
      this.setState({ physicalscore_: "Excellent" });
    }
  };
  handleset3_ = async (index, inx) => {
    const arr = [...this.state.set3];
    arr[inx].answer = this.state.ans3[index].value;
    arr[inx].scale = this.state.ans3[index].scale;
    this.setState({ set3: arr }, () => {
      if (this.state.indx3 + 1 == this.state.set3.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx3: this.state.indx3 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ personalscale_: score });
    console.log(score);
    if (score && score <= 20) {
      this.setState({ personalscore_: "Low" });
    } else if (score && score <= 34 && score >= 21) {
      this.setState({ personalscore_: "Moderate" });
    } else if (score && score <= 40 && score >= 35) {
      this.setState({ personalscore_: "Excellent" });
    } else if (score && score > 40) {
      this.setState({ personalscore_: "Excellent" });
    }
  };
  handleset4_ = async (index, inx) => {
    const arr = [...this.state.set4];
    arr[inx].answer = this.state.ans5[index].value;
    arr[inx].scale = this.state.ans5[index].scale;
    this.setState({ set4: arr }, () => {
      if (this.state.indx4 + 1 == this.state.set4.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx4: this.state.indx4 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ socialscale_: score });
    console.log(score);
    if (score && score <= 28 && score >= 24) {
      this.setState({ socialscore_: "Low" });
    } else if (score && score <= 23 && score >= 13) {
      this.setState({ socialscore_: "Moderate" });
    } else if (score && score <= 12) {
      this.setState({ socialscore_: "Excellent" });
    }
  };
  handleset5_ = async (index, inx) => {
    const arr = [...this.state.set5];
    arr[inx].answer = this.state.ans5[index].value;
    arr[inx].scale = this.state.ans5[index].scale;
    this.setState({ set5: arr }, () => {
      if (this.state.indx5 + 1 == this.state.set5.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx5: this.state.indx5 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    console.log(score);
    this.setState({ professionalscale_: score });
    if (score && score <= 10 && score >= 5) {
      this.setState({ professionalscore_: "Low" });
    } else if (score && score <= 25 && score >= 11) {
      this.setState({ professionalscore_: "Moderate" });
    } else if (score && score <= 35 && score >= 26) {
      this.setState({ professionalscore_: "Excellent" });
    }
  };
  handleset6_ = async (index, inx) => {
    const arr = [...this.state.set6];
    arr[inx].answer = arr[inx].answerOption[index].value;
    arr[inx].scale = arr[inx].answerOption[index].scale;
    this.setState({ set6: arr }, () => {
      if (this.state.indx6 + 1 == this.state.set6.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx6: this.state.indx6 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ financialscale_: score });
    if (score && score <= 5) {
      this.setState({ financialscore_: "Low" });
    } else if (score && score <= 11 && score >= 6) {
      this.setState({ financialscore_: "Moderate" });
    } else if (score && score <= 21 && score >= 12) {
      this.setState({ financialscore_: "Excellent" });
    }
  };

  submitData_ = async () => {
    console.log(this.state.personalscore_);
    const {
      emotional_,
      physical_,
      personal_,
      social_,
      professional_,
      financial_,
      socialscore_,
      emotionalscore_,
      physicalscore_,
      personalscore_,
      professionalscore_,
      financialscore_,
      socialscale_,
      physicalscale_,
      personalscale_,
      professionalscale_,
      financialscale_,
      emotionalscale_,

    } = this.state;
    const emot = emotional_.filter((i) => i.selected === true);
    const soc = social_.filter((i) => i.selected === true);
    const pers = personal_.filter((i) => i.selected === true);
    const phys = physical_.filter((i) => i.selected === true);
    const acad = professional_.filter((i) => i.selected === true);
    const aspi = financial_.filter((i) => i.selected === true);
    let data = JSON.stringify({
      emotional: {
        focus_areas: emot.map((i) => i.value),
        assessment_score: emotionalscale_,
        assesment: this.state.set1,
        total_score:70,
        progress_status:this.state.emotionalscore_,
      },
      social: {
        focus_areas: soc.map((i) => i.value),
        assessment_score: socialscale_,
        assesment: this.state.set4,
        total_score:28,
        progress_status:this.state.socialscore_,
      },
      personal: {
        focus_areas: pers.map((i) => i.value),
        assessment_score: personalscale_,
        assesment: this.state.set3,
        total_score:84,
        progress_status:this.state.personalscore_,
      },
      physical: {
        focus_areas: phys.map((i) => i.value),
        assessment_score: physicalscale_,
        assesment: this.state.set2,
        total_score:38,
        progress_status:this.state.physicalscore_,
      },
      professional: {
        focus_areas: acad.map((i) => i.value),
        assessment_score: professionalscale_,
        assesment: this.state.set5,
        total_score:30,
        progress_status:this.state.professionalscore_
      },
      financial: {
        focus_areas: aspi.map((i) => i.value),
        assessment_score: financialscale_,
        assesment: this.state.set6,
        total_score:37,
        progress_status:this.state.financialscore_,
      },
      positive_areas: [
        socialscore_ == "Excellent" && "Social",
        emotionalscore_ == "Excellent" && "Emotional",
        physicalscore_ == "Excellent" && "Physical",
        personalscore_ == "Excellent" && "Personal",
        professionalscore_ == "Excellent" && "Professional",
        financialscore_ == "Excellent" && "Financial",
      ].filter((i) => i),
      focus_areas: [
        (socialscore_ == "Low" || socialscore_ == "Moderate") && "Social",
        (emotionalscore_ == "Low" || emotionalscore_ == "Moderate") &&
          "Emotional",
        (physicalscore_ == "Low" || physicalscore_ == "Moderate") && "Physical",
        (personalscore_ == "Low" || personalscore_ == "Moderate") && "Personal",
        (professionalscore_ == "Low" || professionalscore_ == "Moderate") &&
          "Professional",
        (financialscore_ == "Low" || financialscore_ == "Moderate") &&
          "Financial",
      ].filter((i) => i),
      patient_n_key: localStorage._patientKey,
      aspiration: {},
      academic: {},
    });
    console.log(JSON.parse(data));
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(REACT_APP_API_URL + "/welbeing_post/", data, { headers })
      .then((res) => {
        console.log(res);
        axios.post(REACT_APP_API_URL+'/wellbeing_tracker/',{patient_n_key:localStorage._patientKey},{headers}).then((res)=>{
          console.log(res)
          if(res.data.status=='success'){
            this.setState({overall_:res.data.data})
          }
        })
      });
  };
  gotoDash = async () => {
    if (this.state.step == 20) {
      this.submitData_();
    }
    window.location.href = "/home";
    this.setState({ step: 1, disable: false });
  };
  goback_ = async () => {
    const { step } = this.state;
    if (step == 1) {
      this.gotoDash();
    } else this.setState({ step: step - 1, disable: false });
  };

  capitalize = (string) => {
    let capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalizedString;
  };
  firstelement=()=>{
    if(this.state.overall_){
      const keys = Object.keys(this.state.overall_);
      const firstKey = keys[0];
      const createdOnValue = this.state.overall_[firstKey];
      return createdOnValue;
    }
  }
  isCreatedWithin14Days = () => {
    const today = moment().startOf('day');
    const createdOnDate = moment(this.firstelement()?.created_on);
    const dateDifferenceInDays = today.diff(createdOnDate, 'days');
    const daysLeft = 14 - dateDifferenceInDays;
    const isWithin14Days = dateDifferenceInDays >= 0 && dateDifferenceInDays <= 14;
    const dateLeft = isWithin14Days ? today.clone().add(daysLeft, 'days') : null;
    return {
      isWithin14Days: isWithin14Days,
      daysLeft: isWithin14Days ? daysLeft : null,
      dateLeft: moment(dateLeft).format('DD-MM-YYYY'),
    };
}

  render() {
    const {
      step,
      indx,
      emotional_,
      set1,
      indx1,
      ans1,
      social_,
      set2,
      indx2,
      personal_,
      set3,
      ans3,
      indx3,
      physical_,
      ans4,
      set4,
      indx4,
      professional_,
      ans5,
      set5,
      indx5,
      ans6,
      set6,
      indx6,
      financial_,
      disable,
      emotionalscore_,
      socialscore_,
      personalscore_,
      physicalscore_,
      professionalscore_,
      financialscore_,
      userinfo,
      showLoader,
      showcofetti,
      showresultmodal,
    } = this.state;
    return (
      showLoader ? (
        <Caremeloader />
      ) : 
      showcofetti ? (
        <FullScreenConfetti />
      ) : showresultmodal ? (
        <>
          <Modal
            centered
            size="lg"
            show={showresultmodal}
            onHide={this.handleToggle}
          >
            <Modal.Body>
              <h3 className="font-weight-light py-3 text-center header_font_">
                🎉 Congratulations on completing your assessment! 🎉
              </h3>
  
              <p className="font-weight-light h5 header_font_">
                At Careme Health, we believe in celebrating every step you take
                towards understanding and nurturing your mental well-being.
                Remember, whatever the outcome, it's the small, consistent changes
                that pave the way to success and resilience. Be proud of yourself
                for taking this step. We're here to support you on your journey.
                ❤️
              </p>
              <p className="font-weight-light py-2 h5 text-center header_font_">
                Stay strong and inspired. Your well-being is worth every effort.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleToggle}>Show Results</Button>
            </Modal.Footer>
          </Modal>
        </>
      ) :  <>
        <div className={step!==20?"topcontainer_ col-md-12 mx-auto d-flex m-0 p-0 pb-5":''}>
          {step !== 20 ? (
           <Col
           md={4}
           className="rounded d-none d-lg-block"
           style={{ background: "#3B316F", minHeight: "680px" }}
         >
           <div className="mt-md-5 pt-md-5">
             <h2 className="text-white text-center">CareMe Health</h2>
             <p className="text-white text-center fw-bold">Feel better. Live better.</p>
           </div>
     
           <h4 className="text-white mt-md-5 ps-md-5">Understand Your Wellbeing</h4>
     
           <div className="mt-4 mx-auto justify-content-center col-md-9 px-md-5">
             {stepsData.map((item, idx) => (
               <StepIndicator key={idx} step={step} label={item.label} isLast={stepsData.length-1 === idx} range={item.range} />
             ))}
           </div>
         </Col>
          ) : null}
          <div ref={this.topRef} className={step!==20?"col-md-7 mx-auto overflow-auto":''}>
           <AssessmentPage />
          </div>
        </div>
        {step == 20 ? (
          <>
           <div  className="d-flex flex-column flex-md-row col-md-10 mx-md-5 mx-0 p-0 p-md-3">
           <div className="col-md-9 mx-auto p-0 table-responsive">
                <div className="col-md-12 mx-auto mt-md-1 d-flex p-0">
              <img
                src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                className="img-fluid"
                style={{ maxHeight: "100px" }}
              />
              <h5
                style={{ opacity: 0.9 }}
                className="header_font_ text-center _text_color align-self-center"
              >
                Your Personalized Wellbeing Roadmap 🗺️ - Embracing Balance and
                Growth
              </h5>
              {(localStorage._nm&&!localStorage._patientKey)? <img src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} className='img-fluid' style={{maxHeight:'100px'}} />
        :null}
            </div>
            <div className="d-lg-none d-sm-block my-2 px-2">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download our mobile app now!
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>

              <table className="table table-bordered pb-2  pb-md-4 table-fixed rounded table-responsive-md border">
              <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                </colgroup>
                <thead>
                  <tr style={{ backgroundColor: "#ddefe5" }}>
                    <th className="py-4 _text_color text-center header_font_ h4">
                   Category
                    </th>
                    <th className="py-4 _text_color header_font_ h4">Needs Attention</th>
                    <th className="py-4 _text_color text-center header_font_ h4">
                    Improving
                    </th>
                    <th className="py-4 _text_color header_font_ h4">
                    Well-balanced
                    </th>
                    <th className="py-4 _text_color text-center header_font_ h4">
                    
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(this.state.overall_).map((category, index) => (
                    <tr
                      onClick={() =>
                        this.props.history.push("/wellbeing-asses-view", {
                          data: this.state.overall_[category],
                        })
                      }
                      className="py-5"
                      key={index}
                    >
                      <td className="text_p_ h5 _text_color py-4">
                        <p>{this.capitalize(category)}</p>
                      </td>
                      <td>
                        {this.state.overall_[category].progress_status ===
                          "Low" && (
                          <div className="position-relative">
                            <div className="banner-text">
                              {this.state.overall_[category].assessment_score}
                            </div>
                            <div
                              className="bg-dark text-center mx-auto pt-4"
                              style={{ height: "34px", width: "3px" }}
                            ></div>
                            <div className="progress mt-0">
                              <div
                                className="progress-bar "
                                role="progressbar"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#f5a97a",
                                }}
                                aria-valuenow="33.33"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        {this.state.overall_[category].progress_status ===
                          "Moderate" && (
                          <div className="position-relative">
                            <div className="banner-text">
                              {this.state.overall_[category].assessment_score}
                            </div>
                            <div
                              className="bg-dark text-center mx-auto pt-4"
                              style={{ height: "34px", width: "3px" }}
                            ></div>
                            <div className="progress mt-0">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#74e86f",
                                }}
                                aria-valuenow="66.66"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        )}
                      </td>
                      <td className="position-relative pr-0">
                        {this.state.overall_[category].progress_status ===
                          "Excellent" && (
                          <div
                            style={{ width: "80%" }}
                            className="mt-0 d-flex flex-row"
                          >
                            <div className="position-relative flex-grow-1">
                              <div className="banner-text">
                                {this.state.overall_[category].assessment_score}
                              </div>
                              <div
                                className="bg-dark text-center mx-auto pt-4"
                                style={{ height: "34px", width: "3px" }}
                              ></div>
                              <div className="progress ">
                                <div
                                  className="progress-bar "
                                  role="progressbar"
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#698ec9",
                                  }}
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </div>
                        )}
                      
                      </td>
                      <td className="text-center">
                        <button style={{background:'#ddefe5',color:'#215352'}} className="btn mt-2">Unlock Recommendations</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div
                class="mx-auto mt-md-5 mt-2 rounded"
                style={{ backgroundColor: "rgba(0, 0, 0, 9%)" }}
              >
                <div class="rounded-box border p-3 d-flex rounded">
                  <div
                    style={{ opacity: 0.7, fontSize: "16px" }}
                    class="text_p_  font-weight-bold"
                  >
                    <b style={{textDecoration:'underline'}}>📅 Next Assessment Date: {this.isCreatedWithin14Days().dateLeft}</b>

{' '}This periodic check-in helps us provide you with the best support and guidance tailored to your evolving needs.

Remember, taking regular assessments allows for better understanding and intervention. Your journey towards better mental health is a continuous process, and we're here to support you every step of the way.
                  </div>
                </div>
              </div>
              <h3 className="mt-3 mt-md-5 header_font_ _text_color ">Trends</h3>
              <div
                class="mx-auto mt-md-3 mt-2 mb-md-5 rounded"
                style={{ backgroundColor: "#dbd5ce" }}
              >
                <div class="rounded-box border rounded p-3">
                  <p class="text-center mx-auto py-1 h1 ">📈</p>
                  <div
                    style={{ opacity: 0.8, fontSize: "16px" }}
                    class="text_p_  font-weight-bold"
                  >
                    If your record has less than two entries, we encourage you
                    to record your well-being at least twice to unlock the full
                    potential of the chart. By recording your well-being on
                    multiple occasions, you can see how different factors can
                    affect your journey and spot valuable patterns and trends.
                  </div>
                </div>
              </div>
              <div className="text-center py-3">
                  <button
                    onClick={this.handleuser}
                    className="btn"
                    style={{
                      background: "#215352",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Go to dashboard
                  </button>
                </div>
            </div>
            <div className="col-md-4 border ml-md-5 ml-2 pt-3 rounded shadow-sm mt-md-5 mt-4" style={{backgroundColor:'#f7cb83',height:'750px',overflow:'hidden',top:'30px',position:'sticky'}}>
                <div style={{borderWidth:'7px'}} className="header_font_ _text_color h2 py-2 border-white text-center border-bottom">Download Our App!</div>
                <p className="text_p_ h5 text-center py-2 _text_color">
                  Get the best experience by downloading our app. Access
                  exclusive features and content.
                </p>
                <div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌿 Elevate your self-care:</strong> Find peace with
                      mindfulness, meditation, and relaxation in our app
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌟 Connect and thrive:</strong> Join a caring
                      community, share, and gain strength together.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🧘‍♂️ Personalized guidance:</strong>Navigate life
                      confidently with expert online counseling.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌈 Expert insights, anytime:</strong> Tailored
                      strategies for your well-being from top psychiatrists.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌌 24/7 support:</strong> Get inspired and guided by
                      coaches, anytime.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌱 Shape your well-being journey:</strong> Your
                      feedback shapes a brighter future for all.
                    </span>
                  </div>
                </div>
                <div className="text-center d-block d-md-none">
                  <button style={{background:'#ed8824',color:'#000',fontWeight:'bold'}} className="btn btn-block">Download now</button>
                  </div>
                  <div className="d-md-block d-none ">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download now
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>

                <div className="d-flex justify-content-between py-2">
                  <img className="mx-1" onClick={() => window.open("https://careme.onelink.me/01QR/bpmu5cly")} style={{width:'170px',height:'50px',cursor:'pointer'}}  src={`${CDN_URL}/Websiteicons/playstore.png`}/>
                  <img className="mx-1" onClick={() => window.open("https://careme.onelink.me/01QR/bpmu5cly")} style={{width:'170px',height:'50px',cursor:'pointer'}}  src={`${CDN_URL}/Websiteicons/appstore.png`} />
                </div>
                <div className="h6 py-2 text-center text_p_">
                  For more details please visit{" "}
                  <a href="https://careme.health" target="_blank">
                    www.careme.health
                  </a>
                </div>
              </div>
              </div>
          </>
        ) : null}
      </>
    );
  }
}
export default wellbeing;

