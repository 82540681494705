import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Collapse,
} from "reactstrap";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, Label } from "reactstrap";
import { REACT_APP_API_URL, REACT_APP_BU_API_URL } from "../processENV";
import Loader from "./Loader";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  Calendar,
  CheckCircle,
  Circle,
  MinusCircle,
  PlusCircle,
  XCircle,
} from "react-feather";
import { withTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import {
  format,
  addDays,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  subDays,
} from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDropleftCircle, IoMdListBox } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postapi } from "../../Helpers/Api";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "0px auto",
      width: theme.spacing(16),
      height: "auto",
      
    },
  },
  paper: {
    width: "100%",
    margin: "0px auto",
  },
  calender: {
    float: "right",
    margin: "20px 10px",
  },
  heading: {
    marginLeft: "20px",
  },
  layout: {
    padding: "40px 20px 20px 20px",
    background:'#f4efe6',
    border:'1px solid #323232',
    // borderRadius:'16px'
  },
  icon: {
    // border: "1px solid #ADD8E6",
    padding: "5px",
    color: "#323232",
    marginTop: "25px",
  },
  Dateicon: {
    marginLeft: "auto",
    // marginTop:'20px',
    position: "absolute",
    top: 10,
    right: 20,
  },
  view: {
    padding: "3px",
    width: "70px",
    margin: "2px auto",
  },
  week: {
    color: "#215352",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "18px",
    margin: "0px auto",
  },
  highl: {
    background: "#fff",
    color: "#edeff3",
    borderRadius: 6,
    boxShadow: "0px 0px 0px 1.5px #c7c1c7",
  },
  day: {
    color: "#000000c9",
    fontSize: "24px",
  },
  dts: {
    position: "absolute",
    top: 10,
    left: 30,
    fontWeight: "bold",
  },
  main: {
    background: '#f4efe6',
    padding: "0px 7px",
    margin: "0px auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
  },
}));

const Calen = (props) => {
  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [item, setItems] = useState([]);
  const history = useHistory();
  const calen = useRef();
  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setStartDate((date) => {
        return subDays(date, 7);
      });
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setStartDate((date) => {
        return addDays(date, 7);
      });
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };
  const ExampleCustomInput = ({ value, onClick }) => {
    return <Calendar className={classes.Dateicon} onClick={onClick} />;
  };
  useEffect(() => {
    if (show) {
      calen.setOpen(true);
    }
  }, [show]);
  const getDate = (day) => {
    let dat = day.trim();
    let date = new Date(dat);
    if (date instanceof Date && !isNaN(date.valueOf())) {
      console.log(new Date(dat));
      setStartDate(new Date(dat));
    } else {
      console.log("invalid");
    }
  };
  useEffect(() => {
    getAsses();
    setItems([]);
  }, [startDate]);

  useEffect(() => {
    getAsses();
  }, []);
  const getAsses = async () => {
    if (
      moment(startDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")
    ) {
      let dat = {
        patient_n_key: localStorage.getItem("_patientKey"),
        request_date: moment(startDate).format("YYYY-MM-DD"),
      };
      fetch(`${REACT_APP_API_URL}/therapy_forms_patient/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(dat),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("allergy list", res);
          setItems(res.data);
        })
        .catch((err) => console.log("allergy list error", err));
    } else {
      toast.error("Please select a date prior to today", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const renderCells = () => {
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d ";
    const dateFormatMonth = " MMM ";
    const dateFormatWeek = "EEE";
    const yearFormat = "yyyy";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    let formattedDateMonth = "";
    let formattedDateWeek = "";
    let formattedYear = "";
    // while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      // console.log(day == startDate);
      formattedDate = format(day, dateFormat);
      formattedDateMonth = format(day, dateFormatMonth);
      formattedDateWeek = format(day, dateFormatWeek);
      formattedYear = format(day, yearFormat);
      days.push(
        <div
          key={day}
          className={`${classes.view}  ${
            startDate == day ? classes.highl : null
          }`}
          style={{ display: "inline-block" }}
        >
          <div className={classes.week}>
            {" "}
            {formattedDateWeek}{" "}
            <span style={{ display: "none" }}>{formattedDate}</span>
            <span style={{ display: "none" }}>{formattedDateMonth}</span>
            <span style={{ display: "none" }}>{formattedYear}</span>
          </div>
          <div className={`${classes.day}`}>
            <span style={{ display: "none" }}>{formattedDateWeek}</span>{" "}
            {formattedDate}
            <span style={{ display: "none" }}>{formattedDateMonth}</span>
            <span style={{ display: "none" }}>{formattedYear}</span>
          </div>
          <div className="hid_span" style={{ color: "#215352" }}>
            <span style={{ display: "none" }}>{formattedDateWeek}</span>
            <span style={{ display: "none" }}>{formattedDate}</span>
            {formattedDateMonth}
            <span style={{ display: "none" }}>{formattedYear}</span>
          </div>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.pop();
    rows.push(<div key={day}>{days}</div>);
    days = [];
    // }

    return (
      <div className={classes.main}>
        <div>
          <IoIosArrowDropleftCircle
            size={50}
            onClick={() => changeWeekHandle("prev")}
            className={classes.icon}
          />{" "}
        </div>
        <Grid container>
          <Grid item lg={12}>
            <div onClick={(event) => getDate(event.target.textContent)}>
              {" "}
              {rows}
            </div>
          </Grid>
        </Grid>
        <div className="icon_centr" style={{ marginLeft: "auto" }}>
          <IoIosArrowDroprightCircle
            size={50}
            onClick={() => changeWeekHandle("next")}
            className={classes.icon}
          />{" "}
        </div>
      </div>
    );
  };
  const gotoResult = async(item)=>{
    const data={...item}
    data.request_date=moment(startDate).format("YYYY-MM-DD")
    data.patient_n_key=localStorage._patientKey
    const res = await postapi('/view_therapy_result/',data)
    if(res.status==='success'){
      props.redirect(res.data)
    }
  }
  return (
    <div className="" style={{height:'100vh',overflowY:'scroll'}}>
      <ToastContainer />
      <div className={` col-md-9 mx-auto`}>
        <Paper
          style={{ position: "relative" }}
          className={`${classes.paper} mt-md-5 m-0`}
          variant="outlined"
          elevation={0}
        >
          <h5 className={`${classes.dts} header_font_`}>
            {moment(startDate).format("DD/MM/YYYY")}
          </h5>
          <div style={{ position: "absolute", right: 20, top: -18 }}>
            <DatePicker
              selected={startDate}
              className="header_font_"
              showYearDropdown
              onChange={(date) => setStartDate(date)}
              customInput={<ExampleCustomInput />}
            />
          </div>
          <div className={`${classes.layout} text_p_`}>{renderCells()}</div>
        </Paper>
        {show ? (
          <DatePicker
            ref={calen}
            className="header_font_"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<ExampleCustomInput />}
          />
        ) : null}
      </div>
      {item && item.length !== 0 ? (
        item.map((item) => {
          return (
            <div key={item.form_name} className="container m-0 p-0">
              <Col md={9} xs={12} className="mx-auto my-2">
                {item.form_name !== "MoodForm" ? (
                  <div className="borderColor rounded my-3 p-3 d-flex flex-row">
                    <span className="align-self-center text_p_">
                      {item.filled ? (
                        <CheckCircle size={16} />
                      ) : (
                        <Circle size={16} />
                      )}{" "}
                    </span>
                    <span className="align-self-center text_p_ ml-2">
                      {item.form_name}
                    </span>
                    <div className="ml-auto align-self-center">
                      {item.filled ? (
                        <button
                        onClick={()=>{
                          if(item.is_result){
                            gotoResult(item)
                          }
                          else console.log('-')
                        }}
                          style={{
                            background: "#fff",
                            color: "#215352",
                            minWidth: "80px",
                            border:'1px solid #215352',
                            borderRadius:'2px'
                          }}
                        >
                          {item.is_result?'View Result':'Taken'}
                        </button>
                      ) : (
                        <>
                          {startDate &&
                          moment(startDate).isSame(moment(), "day") ? (
                            <button
                              style={{
                                // background: secondColor,
                                minWidth: "80px",
                                borderRadius:'2px'
                              }}
                              className="secondColor fontColor borderColor"
                              onClick={() => {
                                history.push("therapyAsses", {
                                  order_form_id: item.order_form_id,
                                });
                              }}
                            >
                              Take now
                            </button>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </Col>{" "}
            </div>
          );
        })
      ) : (
        <>
          <h3 className="text-center" style={{ marginTop: "40px" }}>
            No forms to display
          </h3>
        </>
      )}
    </div>
  );
};

const Workbook = (props) => {
  const [loading, setLoading] = useState(false);

  const redirect=(item)=>{
    props.history.push('/assesment-result-view',{data:{data:item}})
  }
  return (
    <>
      <h4
        style={{ textDecorationLine: "underline" }}
        className="text_p_ font-weight-bolder fontColor  text-center h3  mt-md-5 pt-md-5"
      >
        Well-being Assessment Tool
      </h4>
      <Col md={8} xs={12} className="mx-auto mt-3 pt-2">
        {loading && <Loader />}
        <>
          <Calen redirect={redirect} />
        </>
      </Col>
    </>
  );
};

export default withRouter(Workbook);
