import React from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import LeftContentApp from "./LeftApp";
import Store from "../../../../Store";
// import { ScheduleAction } from './../../../Actions/ScheduleAction';
import { ScheduleApp } from "../../../../Actions/ScheduleApp";
import { REACT_APP_API_URL } from "../../../processENV";
import { withRouter } from "react-router";
import DateApp from "./DateApp";
import TimeApp from "./TimeApp";

class SAIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      item: "",
      scheduleAppstep: 0,
      show: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ScheduleAppData !== this.props.ScheduleAppData) {
      this.setState({
        scheduleAppstep: this.props.ScheduleAppData.data?.scheduleAppstep,
        date: this.props.ScheduleAppData.data?.date,
        time: this.props.ScheduleAppData.data?.time,
      });
    }
  }

  componentDidMount() {
    console.log(this.props.ScheduleAppData?.data?.data1);
    let data = this.props?.ScheduleAppData?.data?.data1;
    if (data !== null || "") {
      this.setState(
        {item: data},
        () =>
          setTimeout(() => {
            this.setState({ show: true });
          }, 1000)
      );
    }
    this.navigateComponent();
  }
  navigateComponent = () => {
    Store.dispatch(
      ScheduleApp({
        scheduleAppstep: 0,
        scheduleCurrentStep: 0,
        date: this.props.ScheduleAppData.data?.date,
        time: this.props.ScheduleAppData.data?.time,
      })
    );
  };

  render() {
    const { scheduleAppstep, item, show } = this.state;
    console.log(scheduleAppstep);
    return (
      <div style={{height:'100vh'}} className={"login-main statusColor"}>
        <Row className="m-0">
          <Col>
            {scheduleAppstep === 0 && show && <DateApp item={item && item} />}
            {scheduleAppstep === 1 && show && <TimeApp item={item && item} />}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    ScheduleAppData: state.ScheduleAppData,
  };
};

export default withRouter(connect(mapStateToProps, null)(SAIndex));
