import React from "react";
import axios from "axios";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import { ArrowDown, ChevronDown } from "react-feather";
import { FileDrop } from "react-file-drop";
import {
  ChevronBack,
  CloudDownloadOutline,
  RefreshOutline,
  SendSharp
} from "react-ionicons";
import ScrollableFeed from "react-scrollable-feed";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import alertSount from "../../assets/images/alert.mp3";
import {
  AGORA_APP_ID,
  CDN_URL,
  REACT_APP_API_URL,
  SITE_URL
} from "../processENV";
import ReceiveMsg from "./ReceiveMsg";
import SendSms from "./SendSms";
import "./canvas.css";
import { BsPlusSquareDotted } from "react-icons/bs";
import { withRouter } from "react-router-dom";
import { ScheduleAction } from "../../Actions/ScheduleAction";
import { ScheduleApp } from "../../Actions/ScheduleApp";
import { synapostApi } from "../../Helpers/Api";
import Store from "../../Store";
const publicIp = require("react-public-ip");

const headers = {
  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
  "Content-Type": "application/json",
};

class AgoraCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.client = {};
    this.messagesEndRef = React.createRef();
    this.localStream = {};
    this.muteTime = 0;
    this.channel = localStorage.channelid;
    this.appId = AGORA_APP_ID;
    this.user = "patient";
    this.appointment_n_key = "CAR1-APT-2067";
    this.org_n_key = "ORGID-1";
    this.patient_n_key = localStorage.patient_n_key;
    this.state = {
      devices: [],
      displayMode: "pip",
      streamList: [],
      readyState: false,
      doctorMuteTime: 0,
      patientMuteTime: 0,
      doctorStartTime: null,
      doctorEndTime: null,
      patientStartTime: null,
      patientEndTime: null,
      showVideo: true,
      showAudio: true,
      isOpen: false,
      volumeopen: false,
      errorMsg: "",
      interval: "",
      docPatmsg: [],
      loading: false,
      url: "",
      appKey: "",
      user: "",
      addResponse: false,
      userJoined: false,
      minScreen: false,
      chatOpen: false,
      selectedFile: null,
      open: false,
      disable: true,
      pt_name: "",
      pt_email: "",
      pt_phone: "",
      prod: "",
      fisrtCounsel: this.props?.location?.state?.firstChat ? true : false,
      modcounsel: this.props?.location?.state?.firstChat ? true : false,
      page: 0,
      total_page: 0,
      total_items: 0,
    };
    this.callbackUser = this.callbackUser.bind(this);
  }
  componentDidMount() {
    localStorage.v__ = 1;
    localStorage.vv__ = 1;
    console.log('s',this.props);
    this.getChat();
    this.getName();
    this.getNewProd();
    this.sendIntialText();
    this.getChatHistory();
    setTimeout(() => {
      this.scrollToBottom();
      this.getNewProd();
    }, 1000);
    this.interval = setInterval(() => {
      this.getChatHistory();
    }, 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      localStorage.vv__ = this.state.page;
      console.log("page changed");
    }
    if (this.refs.chatoutput != null) {
      this.refs.chatoutput.scrollTop = this.refs.chatoutput.scrollHeight;
    }
  }
  componentWillReceiveProps() {
    console.log(this.props, this.props.location.state.data);
    if (this.props.data) {
      this.setState({ docPatmsg: [] });
      this.getChatHistory();
      console.log("popp");
    }
  }
  componentWillUnmount() {
    this.callbackUser(true);
    clearInterval(this.interval);
  }
  componentWillMount() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 1000);
    this.interval = setInterval(() => {
      this.getChatHistory();
    }, 1000);
    this.getChatHistory();
    console.log(this.props, this.props.location.state);
  }
  openChatScreen = () => {
    this.setState({ chatOpen: !this.state.chatOpen });
  };
  getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/app_chathome/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          console.log("item", res);
        } else {
          console.log("meeting id", res.data);
        }
      });
  };
  getName = () => {
    axios
      .post(
        REACT_APP_API_URL + "/patientedit_app/",
        { patient_n_key: localStorage._patientKey },
        { headers }
      )
      .then((res) => {
        if (res.data.status == "success") {
          this.setState({
            pt_name: res.data.data.first_name,
            pt_email: res.data.data.email,
            pt_phone: res.data.data.phone_number,
          });
        }
      });
    setTimeout(() => {
      this.setState({ modcounsel: false });
    }, 3000);
  };
  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  callbackUser(value) {
    console.log("call back value", value);
    if (value == true) {
      this.setState({ userJoined: true });
      console.log("call back true");
    } else {
      this.setState({ userJoined: false });
    }
  }
  start = () => {
    new Audio(alertSount).play();
  };
  onFileChange = (event) => {
    // Update the state
    console.log(event);
    this.setState({
      open: true,
      selectedFile: event.target.files[0],
      disable: false,
    });
    console.log(this.state.open);
    console.log(this.state.selectedFile);
  };
  onDragChange = (files, event) => {
    // Update the state
    console.log(event);
    this.setState({ open: true, selectedFile: files[0], disable: false });
    console.log(this.state.open);
    console.log(this.state.selectedFile);
  };
  onFileUpload = async () => {
    const formData = new FormData();
    formData.append("document_attachment", this.state.selectedFile);
    formData.append("document_name", "chat");
    formData.append("doc_type", "Chat Documents");
    formData.append("patient_n_key", localStorage.getItem("patient_n_key"));
    await axios
      .post(REACT_APP_API_URL + "/appdocument_post/", formData, { headers })
      .then(async (response) => {
        console.log("url", response);
        this.setState({
          selectedFile: null,
          open: false,
          disable: true,
          loading: false,
          url: response.data.data,
        });
        const postfile_data = {
          id: localStorage._patientKey,
          channelid: localStorage.channelName,
          message: response.data.data,
          isSeen: "False",
          patient_n_key: localStorage._patientKey,
          hospital_n_key: "CAR-HOS-1",
          org_n_key: "ORGID-1",
          msg_type: "File",
        };

        console.log(postfile_data);
        const resp = await synapostApi("/chat/send_message/", postfile_data);
        if (resp.status === "suceess") {
          this.setState({
            loading: false,
            open: false,
            disable: true,
            chattext: "",
          });
          this.getChatHistory();
          this.scrollToBottom();
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
        }
      });
  };
  popClose = () => {
    this.setState({
      open: false,
      selectedFile: null,
      disable: true,
    });
    console.log(this.state.open);
    console.log(this.state.selectedFile);
  };
  fileData = () => {
    console.log(this.state.selectedFile);
    if (this.state.selectedFile) {
      return (
        <Modal isOpen={this.state.open} centered onClose={this.popClose}>
          <ModalHeader>
            <h5>Send File Details to chat:</h5>
          </ModalHeader>
          <ModalBody>File Name: {this.state.selectedFile.name}</ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={this.popClose}>
              Cancel
            </Button>
            <Button
              style={{ background: "darkgreen" }}
              variant="primary"
              onClick={this.onFileUpload}
            >
              Send
            </Button>
          </ModalFooter>
        </Modal>
      );
    } else {
      return <div></div>;
    }
  };
  postChatAPI = async (message) => {
    this.setState({
      loading: false,
    });
    const data = {
      id: localStorage._patientKey,
      channelid: localStorage.channelName,
      message: message,
      isSeen: "False",
      patient_n_key: localStorage._patientKey,
      hospital_n_key: "CAR-HOS-1",
      org_n_key: "ORGID-1",
      msg_type: "Text",
      type_of_chat: this.props?.location?.state.typeofchat,
    };
    console.log(data);
    const response = await synapostApi("/chat/send_message/", data);
    if (response.status == "success") {
      this.getChatHistory();
      this.setState({ chattext: "" });
      this.scrollToBottom();
    }
    console.log("Post Message metrics", "response get details:", response.data);
  };
  gotoForms = (id) => {
    this.props.history.push("therapyAsses", { order_form_id: id });
  };
  addMore = () => {
    let d_ = localStorage.v__;
    localStorage.v__ = parseInt(d_) + 1;
  };
  addLess = () => {
    localStorage.v__ = 1;
  };
  getChatHistory = async () => {
    const pValue = parseInt(localStorage.v__);
    const ppValue = parseInt(localStorage.vv__);
    this.setState({ loading: false });
    const response = await synapostApi("/chat/view_chat/", {
      id: localStorage._patientKey,
      channelid: localStorage.channelName,
      page: parseInt(localStorage.v__) || 1,
    });
    if (response.status === "success") {
      if (!isNaN(pValue)) {
        this.setState((prevState) => {
          if (pValue === 1) {
            console.log("a");
            return { docPatmsg: this.groupByDates(response.chat_history) };
          } else if (pValue === ppValue) {
            console.log("b");
            return { docPatmsg: prevState.docPatmsg };
          } else {
            console.log("c");
            let dat_ = [...prevState.docPatmsg];
            let mergedDetails = dat_.flatMap((item) => item.details);
            let uniqueDetails = [...new Set(mergedDetails)];
            let mergedData = [...response.chat_history, ...uniqueDetails];
            let groupedData = this.groupByDates(mergedData);
            console.log("cls", groupedData);
            return { docPatmsg: groupedData };
          }
        });
      } else {
        console.error("localStorage.p__ is not a valid integer.");
      }
      this.setState({
        loading: false,
        page: response.current_page,
        total_page: response.total_pages,
        total_items: response.total_items,
      });
    }
  };
  removeDuplicatesFromDetails(array) {
    const uniqueObjects = [];
    const seenObjects = new Set();
    array.forEach((obj) => {
      const objectString = JSON.stringify(obj);
      if (!seenObjects.has(objectString)) {
        seenObjects.add(objectString);
        uniqueObjects.push(obj);
      }
    });
    return uniqueObjects;
  }
  openPopUp = () => {
    this.setState({ addResponse: false });
    let popup = document.getElementById("careC-popup-wrapper");
    let span = document.getElementById("close");
    popup.classList.add("popQshow");
    span.onclick = function () {
      popup.classList.remove("popQshow");
    };

    window.onclick = function (event) {
      if (event.target == popup) {
        popup.classList.remove("show");
      }
    };
  };
  groupByDates = (data) => {
    const groups = data.reduce((groups, game) => {
      const date = game.time.split(" ")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        details: groups[date],
      };
    });
    return groupArrays;
  };
  sendMessage = (msg) => {
    if(msg.trim().length<2){
      console.log('sdsddsdf')
      toast.error('Minimum 2 characters required!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
   else this.postChatAPI(msg);
  };
  addRes = () => {
    this.setState({
      addResponse: true,
    });
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.sendMessage(this.state.chattext);
    }
  };
  handleChange = (e) => {
    let id = e.target.name;
    this.setState({
      [id]: e.target.value,
    });
    console.log(id, e.target.value);
    let count = e.target.value.trim().length;
    if (count >= 1) {
      this.setState({ disable: false });
    } else {
      this.setState({ disable: true });
    }
  };
  scrollToBottom = () => {
    console.log("coulfnt scrtoll");
    if (this.state.docPatmsg.length > 0) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  getDate = (id) => {
    let val = id;
    return moment(val.date_).format("DD-MM-YYYY");
  };
  getNumbers = (id) => {
    let val = id;
    return val.slots_.length;
  };
  getTherapist = (id) => {
    let val = id;
    return val.therapist_;
  };
  getNewProd = async () => {
    let data = {
      ip: (await publicIp.v4()) || "",
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("new prod", res.data);
        if (res.status === "success") {
          this.setState({ prod: res.data });
        } else {
          console.log("new prod", res.data);
        }
      });
  };
  goIndividual = async () => {
    let prods = this.state.prod?.filter(
      (i) => i.app_prod_grp_key === "APP-PROD-CAT-1"
    );
    const pre_ = await this.checkprevAppointemnt();
    if (pre_ && pre_[0]) {
      let JsonData = {
        patient_n_key: localStorage.getItem("_patientKey"),
        app_prod_grp_key: "APP-PROD-CAT-1",
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(JsonData),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status == "success") {
            if (res.data[0].is_active == 1) {
              let appointmentDetail = {
                doc_app_id: res.data[0].employee_n_key,
                app_prod_grp_key: pre_[0].app_prod_grp_key,
                app_payment_n_key: pre_[0].app_payment_n_key,
                previous_book: "Yes",
                addon: pre_[0].addon || false,
              };
              Store.dispatch(
                ScheduleApp({
                  data1: appointmentDetail,
                })
              );
              this.props.history.push(`/scheduleapp/followup/id`, {
                data1: appointmentDetail,
              });
            } else {
              this.setState({
                selectP: pre_[0],
                creditModal: true,
                empDetails: res.data,
              });
            }
          } else {
            Store.dispatch(
              ScheduleAction({
                data1: pre_[0],
              })
            );
            this.props.history.push("/schedule/appointment", {
              data1: pre_[0],
            });
          }
        });
    } else {
      window.location.assign(
        SITE_URL +
          `/find/user/${
            localStorage._patientKey.split("-")[2]
          }/individual-therapy/book`
      );
    }
  };
  goPsychiatrist = async () => {
    let prods = this.state.prod?.filter(
      (i) => i.app_prod_grp_key === "APP-PROD-CAT-3"
    );
    const pre_ = await this.checkprevAppointemntpsych();
    if (pre_ && pre_[0]) {
      let JsonData = {
        patient_n_key: localStorage.getItem("_patientKey"),
        app_prod_grp_key: "APP-PROD-CAT-3",
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(JsonData),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status == "success") {
            if (res.data[0].is_active == 1) {
              let appointmentDetail = {
                doc_app_id: res.data[0].employee_n_key,
                app_prod_grp_key: pre_[0].app_prod_grp_key,
                app_payment_n_key: pre_[0].app_payment_n_key,
                previous_book: "Yes",
                addon: pre_[0].addon || false,
              };
              Store.dispatch(
                ScheduleApp({
                  data1: appointmentDetail,
                })
              );
              this.props.history.push(`/scheduleapp/followup/id`, {
                data1: appointmentDetail,
              });
            } else {
              this.setState({
                selectP: pre_[0],
                creditModal: true,
                empDetails: res.data,
              });
            }
          } else {
            let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
            Store.dispatch(
              ScheduleAction({
                scheduleStep: 1,
                scheduleCurrentStep: 1,
                problem: selectedProblem,
                mode: "Video",
                data1: pre_[0],
              })
            );
            this.props.history.push("/schedule/appointment", {
              data1: pre_[0],
            });
          }
        });
    } else {
      window.location.assign(
        SITE_URL +
          `/find/user/${
            localStorage._patientKey.split("-")[2]
          }/psychiatric-consultation/book`
      );
    }
  };
  checkprevAppointemnt = async () => {
    const dt_ = JSON.stringify({
      patient_n_key: localStorage.getItem("_patientKey"),
    });
    const res = await axios.post(
      REACT_APP_API_URL + "/upcoming_payment/",
      dt_,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      }
    );
    console.log(res);
    if (
      res.status == "success" &&
      res.data.length > 0 &&
      res.data[0].app_prod_grp_key == "APP-PROD-CAT-1"
    ) {
      console.log(res, "===>");
      return res.data;
    }
    return;
  };
  checkprevAppointemntpsych = async () => {
    const dt_ = JSON.stringify({
      patient_n_key: localStorage.getItem("_patientKey"),
    });
    const res = await axios.post(
      REACT_APP_API_URL + "/upcoming_payment/",
      dt_,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      }
    );
    console.log(res);
    if (
      res.status == "success" &&
      res.data.length > 0 &&
      res.data[0].app_prod_grp_key == "APP-PROD-CAT-3"
    ) {
      console.log(res, "===>");
      return res.data;
    }
    return;
  };
  goBook = async (i) => {
    let data = {
      app_payment_n_key: i.app_payment_n_key,
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/check_already_booked/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == "failed") {
          toast.error("Appointment booked already!");
        } else {
          let appointmentDetail = {
            doc_app_id: i.doc_app_id,
            app_prod_grp_key: i.app_prod_grp_key,
            app_payment_n_key: i.app_payment_n_key,
          };
          Store.dispatch(
            ScheduleApp({
              data1: appointmentDetail,
            })
          );
          this.props.history.push(`/scheduleapp/followup/id`, {
            data1: appointmentDetail,
          });
        }
      });
  };
  goTopay = async (data) => {
    console.log(data);
    localStorage.setItem("orderId", data.followup_payment_details);
    localStorage.setItem("purchase_type", "APPOINTMENT");
    const cf = new global.Cashfree(data.payment_session_id);
    cf.redirect();
  };
  sendIntialText = async () => {
    const defkey = this.props.location?.state?.defkey || null;
    if (this.props?.location?.state.typeofchat == "CareRoom" && defkey) {
      let msf = '';
      if ( defkey == 'slot') {
        msf = `Hi, I'm trying to book an appointment, and it seems that there are currently no available slots with the therapist. I'm in need of support and would appreciate your assistance in finding a solution. Is there any way to check for upcoming availability or explore alternative options? Your help in this matter would be greatly appreciated.`;
      }
      else if (defkey == 'credit') {
        msf = `👋 Hi, I'm in need of assistance regarding booking an appointment. Unfortunately, it appears that I have no available credits to proceed with the session. I'm unsure about how to obtain or add credits to continue receiving support. Could you please guide me on how to resolve this issue? Your help would be greatly appreciated`;
      } 
      else if (defkey == 'invoice') {
        msf = `Hi, I would like to request a physical or softcopy of my invoice for the sessions. Having a physical copy helps me keep a tangible record, while a softcopy is convenient for digital storage and easy access. Could you please assist me in generating both versions of the invoice? If there are any specific details or requirements needed for the invoice, please let me know.`;
      } 
      else if (defkey == 'left') {
        msf = `Hi, I'm trying to book an appointment, and I need some assistance. It appears that the therapist I was previously seeing has left the organization. I'm unsure about the next steps and would appreciate your guidance. Could you please help me navigate through this situation?`;
      }
      this.sendMessage(msf)
    }
  };
  render() {
    const {
      docPatmsg,
      modcounsel,
      fisrtCounsel,
      total_page,
      page,
    } = this.state;
    const data = this.props?.location?.state;
    let getsms = docPatmsg;
    return (
      <div
        style={{ scrollbarWidth: "none" }}
        className="col-md-8 px-md-0 px-1 mx-auto mt-3 pt-4 position-relative"
      >
        <ToastContainer />
        <FileDrop
          onFrameDragEnter={(event) => console.log("onFrameDragEnter", event)}
          onFrameDragLeave={(event) => console.log("onFrameDragLeave", event)}
          onFrameDrop={(event) => console.log("onFrameDrop", event)}
          onDragOver={(event) => console.log("onDragOver", event)}
          onDragLeave={(event) => console.log("onDragLeave", event)}
          onDrop={(files, event) => this.onDragChange(files, event)}
        >
          {this.fileData()}
          <div style={{ scrollbarWidth: "none"}} className="col px-0 pb-5">
            <div
              style={{ borderBottom: "1px solid #ccc", }}
              className="row mt-3 pt-3 align-items-center pb-md-3 p-1 mb-1 bg-white rounded d-flex flex-row justify-content-start"
            >
              <ChevronBack
                onClick={() => {this.props?.history.goBack()}}
                color="#215352"
                size={45}
                style={{ fontSize: "30px", width: "30px", height: "30px" }}
                className=""
              />
              <div className="d-flex ml-2 align-items-center flex-row justify-content-center">
                <div>
                  {data && data.doctor_photo ? (
                    <img
                      src={data.doctor_photo}
                      className="rounded-border"
                      style={{ borderRadius: "50px" }}
                      width="30px"
                      height="30px"
                    />
                  ) : (
                    <img
                      src={`${CDN_URL}/Profile/web%20icon1%20copy.png`}
                      style={{ borderRadius: "50px" }}
                      className="rounded-border"
                      width="30px"
                      height="30px"
                    />
                  )}
                </div>
                <div>
                  <h3 className="header_font_ _text_color pt-2 pl-2 h4">
                    {data.doctor_name
                      ? data.doctor_name
                      : data?.typeofchat == "CareRoom"
                      ? "Care Team"
                      : data?.typeofchat == "CounsellingRoom"
                      ? "Care Coach"
                      : "Therapist"}
                  </h3>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 px-0"
              style={{
                overflow: "scroll",
                height: "500px",
                paddingBottom: "70px",
                scrollbarWidth: "none",
              }}
            >
              <div>
                <Modal size="md" centered isOpen={modcounsel} fade={true}>
                  <ModalBody>
                    <div>
                      <h3 className="header_font_">Congratulations!🎉 </h3>
                      <p className="text_p_">
                        You've just unlocked a 1-Day Free Pass to connect with
                        our Care Coaches here at CareMe Health. We're excited to
                        have you join our community and look forward to
                        supporting your emotional health journey. Don't wait any
                        longer - start chatting with a Care Coach now and make
                        the most of this exclusive offer! 😊
                      </p>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
              <ScrollableFeed>
                {" "}
                {total_page > 1 ? (
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <button
                      style={{
                        backgroundColor: "#ebb859",
                        border: "none",
                        color: "#215352",
                        padding: "6px 18px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: 4,
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                      onClick={() =>
                        total_page === page ? this.addLess() : this.addMore()
                      }
                    >
                      {total_page === page
                        ? "See less"
                        : "Load previous converstation"}
                      {total_page === page ? (
                        <ArrowDown
                          color={"#215352"}
                          height="15px"
                          width="15px"
                        />
                      ) : (
                        <RefreshOutline
                          color={"#215352"}
                          height="15px"
                          width="15px"
                        />
                      )}
                    </button>
                    <button
                      onClick={() =>
                        this.messagesEndRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                      style={{
                        backgroundColor: "#ddefe5",
                        border: "none",
                        color: "#215352",
                        padding: "6px 18px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: 4,
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginLeft: "4px",
                      }}
                    >
                      Go to bottom
                      <ChevronDown
                        color={"#215352"}
                        height="15px"
                        width="15px"
                      />
                    </button>
                  </div>
                ) : null}
                {this.props?.location?.state.typeofchat == "CareRoom" &&getsms?.length===0&&
                   <div style={{backgroundColor:'#f7f7f7'}} className="col-md-6 p-3 rounded mt-3">
                   <div>
                     <div>
                       <p >
                         Hey 👋{' '}
                         <span> {this.capitalize(this.state?.pt_name)}</span> ,
                         If you're in a crisis or at immediate risk of harm to yourself or
                         others, please call emergency services directly or use these{' '}
                         <a target="_blank" href='https://careme.health/gethelpnow'>resources</a>{' '}
                         to get immediate help.
                       </p>
                     </div>
                   </div>
                   <div>
                     <div>
                       <p>
                         We're online from 9.30AM to 10PM (IST) every day.
                       </p>
                     </div>
                   </div>
                 </div>
                }
                {this.props?.location?.state.typeofchat == "CounsellingRoom"&& getsms?.length===0 &&
                fisrtCounsel ? (
                  <div
                    style={{ bottom: "40px" }}
                    className="col-md-12 px-0 position-absolute"
                  >
                    <div className="shadow rounded col-md-6 my-3 bg-white">
                      <p className="text-left my-0 pt-4">
                        We're glad you reached out today!
                      </p>
                      <p className="text-left my-0 pt-2">
                        {" "}
                        <span
                          className="h5"
                          style={{
                            textDecorationLine: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {this.capitalize(this.state?.pt_name)}
                        </span>{" "}
                        We understand you need immediate support.
                      </p>
                      <p className="py-2">Is that correct?</p>
                    </div>
                    <div className="text-center col-md-6 my-4 ml-auto">
                      <button
                        onClick={() => {
                          this.sendMessage("Yes! Get Started");
                          this.setState({ fisrtCounsel: false });
                          this.scrollToBottom()
                        }}
                        className="shadow px-4 btn newbtn py-1 border-none"
                      >
                        Yes! Get Started
                      </button>
                    </div>
                  </div>
                ) : null}
                {getsms &&
                  getsms.map((list) => {
                    console.log(getsms);
                    return (
                      <div
                        style={{ scrollbarWidth: "none" }}
                        className="text-center my-md-4 m-1 "
                      >
                        <p className="text-center">
                          <span className="text-center">
                            {moment(list.date).format("ll") ===
                            moment().format("ll") ? (
                              <span>Today</span>
                            ) : (
                              moment(list.date).format("ll")
                            )}
                          </span>
                        </p>

                        {this.removeDuplicatesFromDetails(list?.details).map(
                          (item, indx) => {
                            return (
                              <div
                                ref={this.messagesEndRef}
                                className="col-md-12 px-0"
                              >
                                {item.id == localStorage._patientKey ? (
                                  <div className="ml-auto col-md-12 px-0">
                                    {item.msg_type === "Text" ? (
                                      <ReceiveMsg
                                        key={indx}
                                        item={item}
                                        newMsg={item.message}
                                        link={
                                          item.message.split("/")[0].trim() ==
                                            "https:" ||
                                          item.message.split(".")[0].trim() ==
                                            "www"
                                            ? true
                                            : false
                                        }
                                      />
                                    ) : (
                                      <div className="col-md-8 text-right ml-auto m-2 mr-n4 mr-md-0">
                                        {item.message.slice(
                                          item.message.length - 4
                                        ) === ".m4a" ||
                                        item.message.slice(
                                          item.message.length - 4
                                        ) === ".mp3" ||
                                        item.message.slice(
                                          item.message.length - 4
                                        ) === ".mp4" ? (
                                          <div>
                                            <ReactAudioPlayer
                                              src={item.message}
                                              controls
                                            />
                                          </div>
                                        ) : item.message.slice(
                                            item.message.length - 4
                                          ) === ".jpg" ||
                                          item.message.slice(
                                            item.message.length - 4
                                          ) === ".png" ||
                                          item.message.slice(
                                            item.message.length - 4
                                          ) === "jpeg" ? (
                                          <div className="d-flex flex-column">
                                            <a
                                              href={item.message}
                                              target="_blank"
                                            >
                                              <img
                                                src={item.message}
                                                style={{width:'220px'}}
                                              />
                                            </a>
                                        <span style={{fontSize:'10px'}} className="text-sm pt-1 pr-1">{moment(item?.time).format("h:mm A")}</span>
                                          </div>
                                        ) : (
                                          <div className="d-flex flex-column">
                                            <a
                                              className="pr-2"
                                              href={item.message}
                                              target="_blank"
                                              style={{fontSize:'18px',textDecorationLine:'underline'}}
                                            >
                                          <span>Download File</span> 
                                          <span>
                                            <CloudDownloadOutline
                                                color={"#00000"}
                                                title="Download"
                                                height="17px"
                                                width="17px"
                                                className="ml-2"
                                              />
                                            </span>
                                            </a>
                                        <span style={{fontSize:'10px'}} className="text-sm pt-1 pr-1">{moment(item?.time).format("h:mm A")}</span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="mr-auto col-md-12 px-0">
                                    {item.msg_type ==
                                    "Recommend Psychiatrist" ? (
                                      <div className="d-flex flex-column rounded col-md-4 col-10 my-3 px-0">
                                        <div style={{backgroundColor:'#f7f7f7'}} className="px-1 pt-2">
                                          <p className="tex_p text-left pl-2">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            {" "}
                                            We understand that you may be going
                                            through a difficult time, and we
                                            want you to know that we're here to
                                            support you. I have recommended a
                                            psychiatric session for you, as we
                                            believe it could be beneficial in
                                            helping you navigate your current
                                            situation.🧘‍♀️
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            {" "}
                                            Please don't hesitate to let us know
                                            if you have any questions or
                                            concerns. We're committed to
                                            providing you with the best care
                                            possible, and we're here for you
                                            every step of the way. 💪
                                          </p>
                                          <p
                                            style={{ opacity: 0.5 }}
                                            className="tex_p text-left pl-2"
                                          >
                                            With Love from Care Team 🤗
                                          </p>
                                        </div>
                                        <div style={{backgroundColor:'#f7f7f7'}} className="text-center">
                                          <button
                                            onClick={() =>
                                              this.goPsychiatrist()
                                            }
                                            className="btn-block shadow px-4 py-1 border-none"
                                            style={{
                                              background: "#215352",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            Book Now
                                          </button>
                                        </div>
                                        <span style={{fontSize:'10px'}} className="text-sm pt-2 pl-2 mr-auto">{moment(item?.time).format("h:mm A")}</span>
                                      </div>
                                    ) : item.msg_type ==
                                      "Recommend Therapist" ? (
                                      <div className="d-flex flex-column rounded col-md-4 col-10 my-3 px-0">
                                        <div  style={{backgroundColor:'#f7f7f7'}} className="px-1 pt-2">
                                          <p className="tex_p text-left pl-2 pt-4">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            We understand that you may be going
                                            through a difficult time, and we
                                            want you to know that we're here to
                                            support you. I have recommended a
                                            psychologist session for you, as we
                                            believe it could be beneficial in
                                            helping you navigate your current
                                            situation.🧠
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            Please don't hesitate to let us know
                                            if you have any questions or
                                            concerns. We're committed to
                                            providing you with the best care
                                            possible, and we're here for you
                                            every step of the way. 💛{" "}
                                          </p>
                                          <p
                                            style={{ opacity: 0.5 }}
                                            className="tex_p text-left pl-2"
                                          >
                                            With Love from Care Team 🤗
                                          </p>
                                        </div>
                                        <div className="text-center">
                                          <button
                                            onClick={() => this.goIndividual()}
                                            className="shadow btn-block px-4 py-1 border-none"
                                            style={{
                                              background: "#215352",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            Book Now
                                          </button>
                                        </div>
                                        <span style={{fontSize:'10px'}} className="text-sm pt-2 pl-2 mr-auto">{moment(item?.time).format("h:mm A")}</span>
                                      </div>
                                    ) : item.msg_type == "Slots" ? (
                                      <div className="rounded col-md-4 col-10 my-3 px-0 flex-column d-flex">
                                        <div style={{backgroundColor:'#f7f7f7'}}  className="py-2 px-1">
                                          <p className="tex_p text-left pl-2 pt-4">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            ! I have some exciting news to share
                                            with you!
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            {" "}
                                            Our provider, 🧑‍⚕️
                                            {this.getTherapist(
                                              JSON.parse(item.message)
                                            )}{" "}
                                            is available on 🗓️{" "}
                                            {this.getDate(
                                              JSON.parse(item.message)
                                            )}
                                            .We have{" "}
                                            {this.getNumbers(
                                              JSON.parse(item.message)
                                            )}{" "}
                                            time slots available on that day,
                                            which are
                                            <p className="d-flex flex-row flex-wrap">
                                              {JSON.parse(
                                                item.message
                                              ).slots_.map((i, indx) => {
                                                return (
                                                  <p
                                                    style={{
                                                      color: "#215352",
                                                      backgroundColor:
                                                        "#ddefe5",
                                                      borderWidth: 0.3,
                                                      borderRadius: 4,
                                                      margin: 4,
                                                      padding: 7,
                                                      alignSelf: "center",
                                                    }}
                                                    key={indx}
                                                  >
                                                    {i}
                                                  </p>
                                                );
                                              })}{" "}
                                            </p>
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            {" "}
                                            If you're interested in booking an
                                            appointment, you can either let me
                                            know and I'll be happy to assist you
                                            in making the reservation, or you
                                            can book directly through our
                                            system.👨‍💻{" "}
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            {" "}
                                            Please don't hesitate to let me know
                                            if you have any questions or if
                                            there's anything else I can help you
                                            with. Thank you! 🙏{" "}
                                          </p>
                                        </div>
                                        <span style={{fontSize:'10px'}} className="text-sm pt-1 pl-2 mr-auto">{moment(item?.time).format("h:mm A")}</span>
                                      </div>
                                    ) : item.msg_type == "FollowupDue" ? (
                                      <div className="rounded col-md-4 col-10 my-3 px-0">
                                        <div style={{backgroundColor:'#f7f7f7'}} className="p-0">
                                          <p className="tex_p text-left pl-2 pt-4">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            We wanted to check in with you since
                                            it's been a while since your last
                                            appointment with us. 😔
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            We're here to support you on your
                                            journey to better health, no matter
                                            how long it takes 🤗. If you're
                                            ready to take the next step and
                                            schedule an appointment, we're ready
                                            to help. 💪
                                          </p>
                                          <p className="tex_p text-left pl-2">
                                            {" "}
                                            Don't hesitate to reach out to us
                                            anytime - we're always here for
                                            you.💬
                                          </p>
                                          <p
                                            style={{ opacity: 0.5 }}
                                            className="tex_p text-left pl-2"
                                          >
                                            Sending love from the Care Team. ❤️
                                          </p>
                                        </div>
                                        <div className="text-center my-4">
                                          <button
                                            onClick={() =>
                                              this.goBook(item.data)
                                            }
                                            className="shadow btn-block px-4 py-1 border-none"
                                            style={{
                                              background: "#215352",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            Book Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type == "Payment" ? (
                                      <div className=" rounded col-md-4 col-10 my-3">
                                        <div  style={{backgroundColor:'#f7f7f7'}} className="p-0 text-left">
                                          <p className="tex_p ">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{
                                              paddingTop: 10,
                                              paddingLeft: 10,
                                            }}
                                          >
                                            {" "}
                                            🎉 We are thrilled to share your
                                            personalized care plan with you! 🤗
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{ paddingLeft: 10 }}
                                          >
                                            💳 If you're ready to pay, simply
                                            click the button below.
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{ paddingLeft: 10 }}
                                          >
                                            Remember, we're always here to help
                                            you on this journey, no matter how
                                            many steps you take. Just reach out
                                            anytime - we'll be here for you! ❤️
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{
                                              paddingLeft: 10,
                                              opacity: 0.5,
                                            }}
                                          >
                                            {" "}
                                            Sending love from the Care Team
                                          </p>
                                        </div>
                                        <div className="text-center my-4">
                                          <button
                                            onClick={() =>
                                              this.goTopay(item.data)
                                            }
                                            className="shadow btn-block px-4 py-1 border-none"
                                            style={{
                                              background: "#215352",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              borderRadius: "4px",
                                              border: "none",
                                            }}
                                          >
                                            Pay Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type == "assesment" ? (
                                      <div className="rounded col-md-6 my-3 bg-white">
                                        <div  style={{backgroundColor:'#f7f7f7'}} className="p-0 text-left">
                                          <p className="tex_p">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{
                                              paddingTop: 10,
                                              paddingLeft: 10,
                                            }}
                                          >
                                            🤔 It looks like you haven't taken
                                            the assessment yet. Please take the
                                            assessment so we can provide the
                                            best support for you. 🧐
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{ paddingLeft: 10 }}
                                          >
                                            Remember, we're always here to help
                                            you on this journey, no matter how
                                            many steps you take. Just reach out
                                            anytime - we'll be here for you! 🤗
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{
                                              paddingLeft: 10,
                                              opacity: 0.5,
                                            }}
                                          >
                                            Sending love from the Care Team. ❤️
                                          </p>
                                        </div>
                                        <div className="text-center my-4">
                                          <button
                                            onClick={() =>
                                              this.props.history.push(
                                                "therapyAsses",
                                                { order_form_id: item.message }
                                              )
                                            }
                                            className="shadow btn-block px-4 py-1 border-none"
                                            style={{
                                              background: "#215352",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              borderRadius: "4px",
                                              border: "none",
                                            }}
                                          >
                                            Take Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type === "Text" ? (
                                      <SendSms
                                        key={indx}
                                        item={item}
                                        newMsg={item.message}
                                        link={
                                          item.message.split("/")[0].trim() ==
                                            "https:" ||
                                          item.message.split(".")[0].trim() ==
                                            "www"
                                            ? true
                                            : false
                                        }
                                      />
                                    ) : (
                                      <div className="col-md-8 text-left mr-auto m-2 ml-n4 ml-md-0">
                                        {item.message.slice(
                                          item.message.length - 4
                                        ) === ".m4a" ||
                                        item.message.slice(
                                          item.message.length - 4
                                        ) === ".mp3" ||
                                        item.message.slice(
                                          item.message.length - 4
                                        ) === ".mp4" ? (
                                          <div>
                                            <ReactAudioPlayer
                                              src={item.message}
                                              controls
                                            />
                                          </div>
                                        ) : item.message.slice(
                                            item.message.length - 4
                                          ) === ".jpg" ||
                                          item.message.slice(
                                            item.message.length - 4
                                          ) === ".png" ||
                                          item.message.slice(
                                            item.message.length - 4
                                          ) === "jpeg" ? (
                                            <div className="d-flex flex-column">
                                            <a
                                              href={item.message}
                                              target="_blank"
                                            >
                                              <img
                                                src={item.message}
                                                style={{width:'220px'}}
                                              />
                                            </a>
                                        <span style={{fontSize:'10px'}} className="text-sm pt-1 pr-1">{moment(item?.time).format("h:mm A")}</span>
                                          </div>
                                        ) : (
                                          <a
                                            className="pl-2"
                                            href={item.message}
                                            target="_blank"
                                          >
                                            Download File
                                            <CloudDownloadOutline
                                              color={"#00000"}
                                              title="Download"
                                              height="17px"
                                              width="17px"
                                            />
                                          </a>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
              </ScrollableFeed>
            </div>
          </div>
        </FileDrop>
        <div
          style={{
            bottom: 0,
            right: 0,
            left: 0,
            background: data.disable ? "#5f779e" : "",
            height: "50px",
          }}
          className="position-absolute px-0 mx-0 col-md-12 py-1 mb-md-2 px-md-3"
        >
          {data.disable ? (
            <div className="text-center mx-auto">
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "16px",
                  paddingTop: "8px",
                }}
              >
                Your Chat Subscription is Expired Kindly{" "}
                <a
                  style={{ color: "#fff", textDecoration: "underline" }}
                  href="/chatpay"
                >
                  Renew
                </a>{" "}
              </p>
            </div>
          ) : (fisrtCounsel&&getsms?.length===0) ? (
            <></>
          ) : (
            <div style={{backgroundColor:'#e8e6e6'}} className="px-md-2 px-0 py-2 d-flex col-md-12 justify-content-center align-items-center">
               <div className="col-1 ml-n4 ml-md-3">
              <label className="pt-1" for="flUpload">
                  <BsPlusSquareDotted size={25} color={"#215352"} height="30px" width="30px" />
                  <input
                    type="file"
                    id="flUpload"
                    onChange={this.onFileChange}
                    style={{ display: "none" }}
                  />
                </label>
                </div>
                <div className="col-10">
              <textarea
                value={this.state.chattext}
                name="chattext"
                className="pt-2 border-0 col-md-12"
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChange}
                placeholder="Enter your message.."
                style={{height:'38px', outline: 'none'}}
              />
              </div>
              <div className="col-1 ml-n4 ml-md-2">
               <SendSharp
                  color={this.state.disable ? "grey" : "#068f1d"}
                  disabled={this.state.chattext < 2}
                  onClick={() => {
                    this.sendMessage(this.state.chattext);
                  }}
                  fontSize={25}
                  size={35}
                  width={"28px"}
                  height={"28px"}
                />
                </div>
              </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AgoraCanvas);
