import React from 'react';
import { ArrowRight } from 'react-feather';

const FirstSessionCallout = ({
  title = "We can’t wait to meet you!",
  subtitle = "Schedule your 1st appointment to get connected to your CareMe Therapist.",
  helpText = "Need help getting started?",
  email = "care@careme.health",
  buttonText = "Book your first session",
  onBook = () => window.location.replace("offerings"),
}) => {
  return (
    <div className="row col-md-8 mx-auto my-auto mt-md-5">
      <div className="mt-md-5">
        <h4 className="text-center fontColor font-weight-bold h3">{title}</h4>
        <p className="text-center fontColor mt-4 h5">{subtitle}</p>
        <p className="h5 mt-4 text-center">{helpText}</p>
        <p className="text-center fontColor mt-1 h5">
          Reach out to{" "}
          <a href={`mailto:${email}`} className="font-weight-bolder">
            {email}
          </a>{" "}
          and a team member will respond as soon as possible.
        </p>
      </div>

      <div className="text-center mx-auto mt-4">
        <button
          className="btn newbtn font-weight-bold px-4 py-2 btn-md btn-block"
          onClick={onBook}
        >
          {buttonText} <ArrowRight />
        </button>
      </div>
    </div>
  );
};

export default FirstSessionCallout;
